import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from './auth';
import { AlertComponent, SpinnerComponent, NotifyComponent, EditableLabelComponent, ValidationHintComponent, TagComponent, SelectableLabelComponent } from './directives';
import { MinValueValidatorDirective, ToUpperCaseDirective, FormIgnoreDirective, NormalizeDirective } from './directives';
import { MultiRequireValidatorDirective, ConfirmComponent, ConfirmContent, TrimDirective } from './directives';
import { RomanPipe, HtmlPipe, NumFormatPipe } from './directives';
import { DataService, AlertService, SpinnerService, NotifyService } from './';
import { UserService, CustomerService, ReportService, ReportLightService, SpecificationService, ValueService} from './services';
import { TemplateService, TextblockService, DocxService, FunctionService, InciService, IngredientService } from './services';
import { DeactivateGuardService } from './deactivateGuard';

@NgModule({
  declarations: [
    AlertComponent,
    SpinnerComponent,
    NotifyComponent,
    ConfirmComponent,
    ConfirmContent,
    EditableLabelComponent,
    SelectableLabelComponent,
    ValidationHintComponent,
    TagComponent,
    MultiRequireValidatorDirective,
    MinValueValidatorDirective,
    NormalizeDirective,
    TrimDirective,
    ToUpperCaseDirective,
    FormIgnoreDirective,
    RomanPipe,
    HtmlPipe,
    NumFormatPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgbTooltipModule
  ],
  exports: [
    AlertComponent,
    SpinnerComponent,
    NotifyComponent,
    ConfirmComponent,
    EditableLabelComponent,
    SelectableLabelComponent,
    ValidationHintComponent,
    TagComponent,
    MultiRequireValidatorDirective,
    MinValueValidatorDirective,
    NormalizeDirective,
    TrimDirective,
    ToUpperCaseDirective,
    FormIgnoreDirective,
    RomanPipe,
    HtmlPipe,
    NumFormatPipe
  ],
  providers: [
    AlertService,
    AuthenticationService,
    CustomerService,
    DataService,
    DeactivateGuardService,
    DocxService,
    FunctionService,
    InciService,
    IngredientService,
    NotifyService,
    ReportService,
    ReportLightService,
    SpecificationService,
    SpinnerService,
    TemplateService,
    TextblockService,
    UserService,
    ValueService
  ],
})
export class AppCommonsModule { }
