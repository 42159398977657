import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './home/landing.component';
import { LoginComponent } from './home/login.component';
import { UsersComponent, UserNewComponent } from './users';
import { ReportEditComponent } from './reports';
import { AuthGuard } from './common/auth';
import { CustomersComponent, CustomerNewComponent } from './masterdata';
import { IngredientsComponent, IngredientNewComponent, IncisComponent, InciNewComponent  } from './masterdata';
import { SpecificationsComponent, SpecificationNewComponent, FunctionsComponent, FunctionNewComponent } from './masterdata';
import { TemplatesComponent, TemplateNewComponent, TemplateHistoryComponent, DocxsComponent } from './masterdata';
import { CorrectionsComponent, CorrectionNewComponent, ProductTagsComponent, ProductTagNewComponent } from './masterdata';
import { DeactivateGuard } from './common';
import { NumberPrecisionComponent } from './admin';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: '', component: LandingComponent, canActivate: [AuthGuard] },
    { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
    { path: 'users/new', component: UserNewComponent, canActivate: [AuthGuard] },
    { path: 'users/:key', component: UsersComponent, canActivate: [AuthGuard] },
    { path: 'users/:key/:action', component: UsersComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
    { path: 'customers/new', component: CustomerNewComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },
    { path: 'customers/:key', component: CustomersComponent, canActivate: [AuthGuard] },
    { path: 'customers/:key/:action', component: CustomersComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'ingredients', component: IngredientsComponent, canActivate: [AuthGuard] },
    { path: 'ingredients/new', component: IngredientNewComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },
    { path: 'ingredients/:key', component: IngredientsComponent, canActivate: [AuthGuard] },
    { path: 'ingredients/:key/:action', component: IngredientsComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'incis', component: IncisComponent, canActivate: [AuthGuard] },
    { path: 'incis/new', component: InciNewComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },
    { path: 'incis/:key', component: IncisComponent, canActivate: [AuthGuard] },
    { path: 'incis/:key/:action', component: IncisComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'specifications', component: SpecificationsComponent, canActivate: [AuthGuard] },
    { path: 'specifications/new', component: SpecificationNewComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },
    { path: 'specifications/:key', component: SpecificationsComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },
    { path: 'specifications/:key/:action', component: SpecificationsComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'functions', component: FunctionsComponent, canActivate: [AuthGuard] },
    { path: 'functions/new', component: FunctionNewComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },
    { path: 'functions/:key', component: FunctionsComponent, canActivate: [AuthGuard] },
    { path: 'functions/:key/:action', component: FunctionsComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'templates', component: TemplatesComponent, canActivate: [AuthGuard] },
    { path: 'templates/new', component: TemplateNewComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },
    { path: 'templates/:key', component: TemplatesComponent, canActivate: [AuthGuard] },
    { path: 'templates/:key/history', component: TemplateHistoryComponent, canActivate: [AuthGuard] },
    { path: 'templates/:key/:action', component: TemplatesComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'values/correction', component: CorrectionsComponent, canActivate: [AuthGuard] },
    { path: 'values/correction/new', component: CorrectionNewComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },
    { path: 'values/correction/:key', component: CorrectionsComponent, canActivate: [AuthGuard] },
    { path: 'values/correction/:key/:action', component: CorrectionsComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'values/product-tag', component: ProductTagsComponent, canActivate: [AuthGuard] },
    { path: 'values/product-tag/new', component: ProductTagNewComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },
    { path: 'values/product-tag/:key', component: ProductTagsComponent, canActivate: [AuthGuard] },
    { path: 'values/product-tag/:key/:action', component: ProductTagsComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'docxs', component: DocxsComponent, canActivate: [AuthGuard] },
    { path: 'docxs/:key', component: DocxsComponent, canActivate: [AuthGuard] },

    { path: 'reports/:key', component: ReportEditComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateGuard] },

    { path: 'admin/test/precision', component: NumberPrecisionComponent, canActivate: [AuthGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
