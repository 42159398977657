<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <br>
  <div class="row">
    <div class="col-11">
      <h3>{{val.label}}</h3>

      <div class="row">
        <div class="col-6 col-md-6 col-lg-4 form-goup form-check form-check-aligned">
          <input class="form-check-input" type="checkbox" readonly disabled [(ngModel)]="val.default">
          <label class="form-check-label">Default</label>
        </div>

      </div>
    </div>
    <div class="col-1">
      <div class="form-group" ngbTooltip="Edit" tooltipClass="dt-tooltip">
        <a [routerLink]="['/values/product-tag', val.key, 'edit']" class="form-control btn"><span class="float-end oi oi-pencil"></span></a>
      </div>
      <div class="form-group" ngbTooltip="Delete" tooltipClass="dt-tooltip">
        <button (click)="delete()" class="form-control btn"><span class="float-end oi oi-x"></span></button>
      </div>
    </div>
  </div>
</div>
