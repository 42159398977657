import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { DataService } from '../data.service';
import { ReportService } from '../../common/services/report.service';

@Injectable({
    providedIn: 'root'
})
class AuthGuardService  {

    constructor(private router: Router, private dataService: DataService, private reportService: ReportService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            console.log('AuthGuard.canActivate()', 'Checking route access', state.url, ' -> OK');
            if (this.dataService) {
                let activeReportKey: number = null;
                if (this.dataService.activeReport) {
                    activeReportKey = this.dataService.activeReport.key;
                }
                let storedKey: number = null;
                if (localStorage.getItem('activeReport')) {
                    storedKey = Number(localStorage.getItem('activeReport'));
                }
                const target = activeReportKey ? activeReportKey : storedKey;
                if (target) {
                    localStorage.setItem('activeReport', ''+target);
                }
                if (target && !this.dataService.activeReport) {
                    this.reportService.getByKey(target)
                        .then(r => this.reportService.loadDetails(r))
                        .then(r => {
                            console.log('AuthGuard.canActivate()', 'Restore active report after refresh: ', route.queryParams.activeReport);
                            this.dataService.activeReport = r;
                        })
                        .finally(() => {
                            if (!this.dataService.activeReport) {
                                return this.router.navigate(['/']);
                            }
                        });
                }
            }
            return true;
        }

        // not logged in so redirect to login page with the return url
        console.log('AuthGuard.canActivate():', 'Checking route access', state.url, ' -> No authentication');
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    return inject(AuthGuardService).canActivate(next, state);
}
  