import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Value } from '../model';
import { DataService } from '../data.service';
import { EntityStore } from './entity_store';

@Injectable()
export class ValueService extends EntityStore<Value> {

    constructor(http: HttpClient, dataService: DataService) {
        super(http, dataService.values, '/api/values', Value);

        this.assertLoaded = (searchUri?: string) => {
            return super.assertLoaded(searchUri).then(() => {
                this.data.sort(
                    (a: Value, b: Value) => a.context.localeCompare(b.context) || a.type.localeCompare(b.type) || a.label.localeCompare(b.label)
                );
            });
        };
    }

}
