import { BaseEntity } from './base_entity';

export class Value extends BaseEntity {

  constructor(source?: Value) {
    super(source);

    this.default = this.default ? true: false;
    this.properties = this.properties || {}
  }

  context: string;
  type: string;
  label: string;
  default: boolean;

  properties: any;

}

export class InciRouteCorrection extends Value {

  constructor(source?: Value) {
    super(source);

    this.context = this.context || 'inci';
    this.type = this.type || 'route-correction';
    this.properties.text = this.properties.text || '';
    this.properties.factor = this.properties.factor || 1.0;
  }

  properties: { text: string, factor: number};

}

export class ProductTag extends Value {

  constructor(source?: Value) {
    super(source);

    this.context = this.context || 'product';
    this.type = this.type || 'product-tag';
  }

  properties: { default: boolean};

}
