import { Inci } from './inci.model';
import { BasePortion } from './base_portion.model';

export class IngredientInci extends BasePortion {
    inciKey: number;
    ref: Inci;

    constructor(source?: IngredientInci) {
        super(source);
    }
}
