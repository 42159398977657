<div class="container-fluid">
  <div *ngIf="data.activeReport">
    <form #form="ngForm" class="row">
    <app-sidenav class="col-lg-2 col-md-3 col-sm-4 col-6">
      <div>
        <br/>
        <p><button 
          *ngIf="!data.activeReport.published"
          class="btn btn-primary form-control" 
          (click)="save($event)">Save</button></p>
        <p><button 
          *ngIf="data.activeReport.published"
          class="btn btn-primary" 
          (click)="reopen($event)">Reopen</button></p>
        <p><button
          *ngIf="data.activeReport.id"
          class="btn btn-outline-primary" 
          (click)="duplicate($event)">Duplicate</button></p>
        <p><button
          *ngIf="data.activeReport.id && !data.activeReport.published"
          class="btn btn-outline-primary" 
          (click)="delete($event)">Delete</button></p>
        </div>
    </app-sidenav>
    <main class="col-lg-10 col-md-9 col-sm-8 col-6" role="main">
      <h2>{{data.activeReport.title ? data.activeReport.title : 'New Report'}} {{data.activeReport.productName? '-' : ''}} {{data.activeReport.productName}}</h2> 
      <app-alert></app-alert>

      <!-- Accordion -->
      <div class="container-fluid">
        <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="panel-0">

          <!-- Panel: Basics -->
          <ngb-panel id="panel-0" class="data_ok">
            <ng-template ngbPanelTitle>
              <span class="oi oi-caret-right" [ngClass]="{'card-open': acc.panels._results[0].isOpen, 'valid': data.activeReport.isSectionValid(section.Basics)}"></span>&nbsp;
              <span [ngClass]="{'valid': data.activeReport.isSectionValid(section.Basics)}">Basics</span>
              <span class="float-end oi" 
                  [ngClass]="{'oi-check valid': data.activeReport.isSectionValid(section.Basics), 'oi-pencil': !data.activeReport.isSectionValid(section.Basics) }">
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <!-- Validations -->
              <app-validation *ngIf="!data.activeReport.published && hasObsoleteTemplate()"
                  [message]="'The root template was updated. This report still references its previous version.'">
                <button class="btn btn-outline-secondary"
                  ngbTooltip="Refresh root template to its latest edited versions" tooltipClass="dt-tooltip" container="body"
                  (click)="refreshTemplate()">Refresh Template</button>
              </app-validation>
              <app-validation *ngIf="!data.activeReport.published && hasAnyObsoleteCustomerRef()"
                  [message]="'At least one referenced customer record was updated. This report still references its previous version.'">
                <button class="btn btn-outline-secondary"
                  ngbTooltip="Refresh customer to its latest edited versions" tooltipClass="dt-tooltip" container="body"
                  (click)="refreshAllCustomerRefs()">Refresh References</button>
              </app-validation>
              <!-- Form fields -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label for="title">Title*</label>
                  <input id="title" name="title" type="text" [(ngModel)]="data.activeReport.title" #title="ngModel" required appTrim [disabled]="data.activeReport.published" class="form-control" (change)="unfreeze()">
                  <div [hidden]="title.valid || title.pristine || title.disabled" class="alert alert-danger">Title is required</div>
                </div>
                <div class="col-md-6 form-group">
                  <label for="study">Study Number*</label>
                  <input id="study" name="study" type="text" [(ngModel)]="data.activeReport.studyNumber" #study="ngModel" required appTrim [disabled]="data.activeReport.published" class="form-control" (change)="unfreeze()">
                  <div [hidden]="study.valid || study.pristine || study.disabled" class="alert alert-danger">Study number is required</div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 form-group">
                  <label for="template">Template*</label>
                  <div class="form-inline">
                    <select id="template" name="template"
                        [(ngModel)]="data.activeReport.template" #template="ngModel"
                        required [disabled]="data.activeReport.published" (change)="templateChanged()"
                        class="form-select w-75" >
                      <option *ngIf="hasObsoleteTemplate()" [ngValue]="data.activeReport.template">{{data.activeReport.template.name}}</option>
                      <option [ngValue]="temp" *ngFor="let temp of data.templates.roots(data.activeReport.language)">{{temp.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label for="language">Language*</label>
                  <div class="form-inline">
                    <select id="language" name="language"
                        [(ngModel)]="data.activeReport.language" #language="ngModel"
                        required [disabled]="data.activeReport.published" (change)="languageChanged()"
                        class="form-select w-75" >
                      <option *ngFor="let l of languages" value="{{l.key}}">{{l.label}}</option>
                    </select>
                  </div>
                  <div [hidden]="language.valid || language.pristine || language.disabled" class="alert alert-danger">Please select a language</div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 form-group">
                  <label for="productName">Product Name*</label>
                  <input id="productName" name="productName" type="text" [(ngModel)]="data.activeReport.productName" #productName="ngModel" required appTrim [disabled]="data.activeReport.published" class="form-control" (change)="unfreeze()">
                  <div [hidden]="productName.valid || productName.pristine || productName.disabled" class="alert alert-danger">Product name is required</div>
                </div>
                <div class="col-md-6 form-group">
                  <label for="prodtype">Product Type</label>
                  <input id="prodtype" name="prodtype" type="text" [(ngModel)]="data.activeReport.prodtype" #prodtype="ngModel" appTrim [disabled]="data.activeReport.published" class="form-control" (change)="unfreeze()">
                </div>
              </div>
              <div class="row">
                <div class="col-12">Product Tags</div>
                <div class="col-12 form-group form-inline">
                  <span *ngIf="!(data.activeReport.productTags.list && data.activeReport.productTags.list.length)" class="form-control form-control-plaintext me-2"><em>-- No product tags --</em></span>
                  <app-tag *ngFor="let t of data.activeReport.productTags.list" [label]="t.label" (cancel)="removeProductTag(t)"></app-tag>
                  <select id="prod_tags" name="prod_tags" class="form-control w-25 form-select"
                      [(ngModel)]="productTagKey" #productTag="ngModel"
                      [disabled]="data.activeReport.published"
                      (change)="addProductTag()">
                    <option [ngValue]="null" [selected]="productTagKey == null">-- Product Tags --</option>
                    <option *ngFor="let t of data.values.productTags()" [ngValue]="t.key">{{t.label}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label for="recipe">Recipe Number*</label>
                  <input id="recipe" name="recipe" type="text" [(ngModel)]="data.activeReport.recipeNumber" #recipe="ngModel" required appTrim [disabled]="data.activeReport.published" class="form-control" (change)="unfreeze()">
                  <div [hidden]="recipe.valid || recipe.pristine || recipe.disabled" class="alert alert-danger">Recipe number is required</div>
                </div>
                <div class="col-md-6 form-group">
                  <label for="cpnp">CPNP</label>
                  <input id="cpnp" name="cpnp" type="text" [(ngModel)]="data.activeReport.cpnp" #cpnp="ngModel" appTrim [disabled]="data.activeReport.published" class="form-control" (change)="unfreeze()">
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 form-group">
                  <label for="customer">Sponsor*</label>
                  <div class="form-inline">
                    <select id="customer" name="customer"
                        [(ngModel)]="data.activeReport.customer" #customer="ngModel"
                        required [disabled]="data.activeReport.published" (change)="customerChanged()"
                        class="form-select w-75" >
                      <option *ngIf="hasObsoleteCustomer()" [ngValue]="data.activeReport.customer">{{data.activeReport.customer.name}}</option>
                      <option [ngValue]="cust" *ngFor="let cust of data.customers.list">{{cust.name}}</option>
                    </select>
                  </div>
                  <div [hidden]="customer.valid || customer.pristine || customer.disabled" class="alert alert-danger">Please select a customer as sponsor</div>
                </div>
                <div class="col-md-6 form-group">
                  <label for="manufacturer">Manufacturer*</label>
                  <div class="form-inline">
                    <select id="manufacturer" name="manufacturer"
                        [(ngModel)]="data.activeReport.manufacturer" #manufacturer="ngModel"
                        required [disabled]="data.activeReport.published" (change)="manufacturerChanged()"
                        class="form-select w-75" >
                      <option *ngIf="hasObsoleteManufacturer()" [ngValue]="data.activeReport.manufacturer">{{data.activeReport.manufacturer.name}}</option>
                      <option [ngValue]="cust" *ngFor="let cust of data.customers.list">{{cust.name}}</option>
                    </select>
                  </div>
                  <div [hidden]="manufacturer.valid || manufacturer.pristine || manufacturer.disabled" class="alert alert-danger">Please select a customer as manufacturer</div>
                </div>
                <ng-template #tooltipBottler>Only if different from manufacturer.</ng-template>
                <div class="col-md-6 form-group">
                  <label for="bottler">Bottler</label>
                  <div class="form-inline" [ngbTooltip]="tooltipBottler" tooltipClass="dt-tooltip">
                    <select id="bottler" name="bottler"
                        [(ngModel)]="data.activeReport.bottler" #bottler="ngModel"
                        [disabled]="data.activeReport.published" (change)="bottlerChanged()"
                        class="form-select w-75" >
                      <option>-</option>
                      <option *ngIf="hasObsoleteBottler()" [ngValue]="data.activeReport.bottler">{{data.activeReport.bottler.name}}</option>
                      <option [ngValue]="btl" *ngFor="let btl of data.customers.list">{{btl.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label for="distributor">Distributor</label>
                  <div class="form-inline" [ngbTooltip]="tooltipBottler" tooltipClass="dt-tooltip">
                    <select id="distributor" name="distributor"
                        [(ngModel)]="data.activeReport.distributor" #distributor="ngModel"
                        [disabled]="data.activeReport.published" (change)="distributorChanged()"
                        class="form-select w-75" >
                        <option>-</option>
                        <option *ngIf="hasObsoleteDistributor()" [ngValue]="data.activeReport.distributor">{{data.activeReport.distributor.name}}</option>
                        <option [ngValue]="dst" *ngFor="let dst of data.customers.list">{{dst.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
          </ng-template>
          </ngb-panel>

          <!-- Panel: Users -->
          <ngb-panel id="panel-1">
            <ng-template ngbPanelTitle>
              <span class="oi oi-caret-right" [ngClass]="{'card-open': acc.panels._results[1].isOpen, 'valid': data.activeReport.isSectionValid(section.Users)}"></span>&nbsp;
              <span [ngClass]="{'valid': data.activeReport.isSectionValid(section.Users)}">Users</span>
              <span class="float-end oi" [ngClass]="{'oi-check valid': data.activeReport.isSectionValid(section.Users), 'oi-pencil': !data.activeReport.isSectionValid(section.Users) }"></span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label for="author">Author*</label>
                  <select id="author" name="author" [(ngModel)]="data.activeReport.author" #author="ngModel" required [disabled]="data.activeReport.published" class="form-select" (change)="unfreeze()">
                    <option [ngValue]="user" *ngFor="let user of data.users.authors()">{{user.givenName}} {{user.surname}}</option>
                  </select>
                  <div [hidden]="author.valid || author.pristine || author.disabled" class="alert alert-danger">Reports must have an author</div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <label for="coAuthor0">Co-Author*</label>
                    <select id="coAuthor0" name="coaAuthor0" [(ngModel)]="data.activeReport.coAuthors[0]" #coAuthor0="ngModel" required [disabled]="data.activeReport.published" class="form-select" (change)="unfreeze()">
                      <option [ngValue]="user" *ngFor="let user of data.users.authors()">{{user.givenName}} {{user.surname}}</option>
                    </select>
                    <div [hidden]="coAuthor0.valid || coAuthor0.pristine || coAuthor0.disabled" class="alert alert-danger">Reports must have two Co-Authors</div>
                  </div>
                  <div>
                    <label for="coAuthor1">Co-Author*</label>
                    <select id="coAuthor1" name="coaAuthor1" [(ngModel)]="data.activeReport.coAuthors[1]" #coAuthor1="ngModel" required [disabled]="data.activeReport.published" class="form-select" (change)="unfreeze()">
                        <option [ngValue]="user" *ngFor="let user of data.users.authors()">{{user.givenName}} {{user.surname}}</option>
                    </select>
                    <div [hidden]="coAuthor1.valid || coAuthor1.pristine || coAuthor1.disabled" class="alert alert-danger">Reports must have two Co-Authors</div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>

          <!-- Panel: Ingredients -->
          <ng-template #tooltipProportions>only Min value: "4 %"<br/>Min, Max value: "4 - 5 %"<br/>only Max value: "&lt; 4 %"</ng-template>
          <ngb-panel id="panel-2">
            <ng-template ngbPanelTitle>
              <span class="oi oi-caret-right" [ngClass]="{'card-open': acc.panels._results[2].isOpen, 'valid': data.activeReport.isSectionValid(section.Ingredients)}"></span>&nbsp;
              <span [ngClass]="{'valid': data.activeReport.isSectionValid(section.Ingredients)}">Raw Materials</span>
              <span class="float-end oi" [ngClass]="{'oi-check valid': data.activeReport.isSectionValid(section.Ingredients), 'oi-pencil': !data.activeReport.isSectionValid(section.Ingredients) }"></span>
            </ng-template>
            <ng-template ngbPanelContent>
              <!-- Validations -->
              <app-validation *ngIf="!data.activeReport.published && hasObsoleteIngredients().length > 0"
                  [message]="'Some raw materials were updated (' + hasObsoleteIngredients().join(', ') + '). This report still references their previous versions.'">
                <button class="btn btn-outline-secondary"
                  ngbTooltip="Refresh all raw materials to their latest edited versions" tooltipClass="dt-tooltip" container="body"
                  (click)="refreshIngredients()">Refresh</button>
              </app-validation>
              <!-- Table -->
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-striped"><tbody>
                    <tr>
                      <th style="width: 1%;">No.</th>
                      <th>Name</th>
                      <th>INCI</th>
                      <th>Proportion</th>
                      <th><!-- Actions --></th>
                    </tr>
                    <tr *ngIf="data.activeReport.ingredients.length == 0">
                      <td colspan="4" class="p-0">
                        <div class="w-25 ng-invalid p-1 d-inline-block">&nbsp;</div>
                        <div class="p-1 d-inline-block">-- List is empty --</div>
                      </td>
                    </tr>
                    <ng-container *ngFor="let ingredient of data.activeReport.ingredients; index as i">
                      <tr>
                        <td style="width: 1%;">
                          <!-- row index -->
                          <span>{{i+1}}</span>
                        </td>
                        <td>
                          <!-- ingredient -->
                          <span class="ingredient">
                            <a *ngIf="ingredient.ref.id && !hasObsoleteIngredient(ingredient)" [routerLink]="['/ingredients', ingredient.ref.key]" class="nav-link">{{ingredient.ref.getName()}}</a>
                            <span *ngIf="ingredient.ref.id && hasObsoleteIngredient(ingredient)" 
                              ngbTooltip="Raw material was deleted or updated" tooltipClass="dt-tooltip" container="body">{{ingredient.ref.getName()}} </span>
                            <span *ngIf="!ingredient.ref.id">
                              <app-editable-label name="ingredientName" [obj]="ingredient.ref" field="tradename" placeholder="Raw Material name" (input)="form.form.markAsDirty()"></app-editable-label>
                            </span>

                            <sub *ngIf="ingredient.ref.language != data.activeReport.language"> ({{ingredient.ref.language}})</sub>
                            <span *ngIf="ingredient.ref.name && ingredient.ref.name != ingredient.ref.tradename">&nbsp;{{ingredient.ref.tradename}}</span>
                            <span *ngIf="ingredient.ref.manufacturer">&nbsp;({{ingredient.ref.manufacturer}})</span>
                          </span>
                          <span *ngIf="ingredient.ref.id">
                            <button type="button" (click)="showAddAlternative = ingredient" *ngIf="!data.activeReport.published" class="btn small" 
                            ngbTooltip="Add alternative Raw Material" tooltipClass="dt-tooltip" container="body"><span class="oi oi-plus"></span></button>
                          </span>
                        </td>
                        <td>
                          <!-- INCIs -->
                          <div *ngIf="ingredient.ref.incis.length == 0">
                              <div class="ng-invalid p-1">-- List is empty --</div>
                          </div>
                          <div *ngFor="let inci of ingredient.ref.incis; index as j">
                            <div *ngIf="ingredient.ref.id">
                              <span *ngIf="!inci.ref.obsolete">
                                <a [routerLink]="['/incis', inci.ref.key]" class="nav-link">{{inci.ref.name}}</a>
                                <sub class="dt-eukv" *ngIf="inci.ref.isEukvAppendix()">
                                  <span *ngFor="let a of inci.ref.eukv.list; index as k">
                                    <span *ngIf="k>0">,&nbsp;</span>
                                    <span [ngbTooltip]="eukvLabels.label(a.appendix)" tooltipClass="dt-tooltip">{{a.appendix | roman}}</span>
                                  </span>
                                </sub>
                                <span *ngIf="inci.ref.isEukvAppendix() && inci.ref.getRoute()">&nbsp;</span>
                                <sub class="dt-inci-route" *ngIf="inci.ref.getRoute()">
                                  <span *ngFor="let r of inci.ref.getRouteInfo(); index as k">
                                    <span *ngIf="k>0">,&nbsp;</span>
                                    <span *ngIf="r" [ngbTooltip]="r.value + ': ' + inci.ref.getRoute(r.value).value + ' (' + inci.ref.getRoute(r.value).type + ')'" tooltipClass="dt-tooltip">{{r.short}}</span>
                                    <span *ngIf="!r" [ngbTooltip]="'Unknown route: ' + inci.ref.getRoute().value" tooltipClass="dt-tooltip">?</span>
                                  </span>
                                </sub>
                                <span>: {{inci.formatPercent('en')}}&nbsp;%</span>
                              </span>
                              <span *ngIf="inci.ref.obsolete" 
                                ngbTooltip="INCI was deleted or updated" tooltipClass="dt-tooltip" container="body">{{inci.ref.name}} : {{inci.formatPercent('en')}}&nbsp;%</span>
                            </div>
                            <div *ngIf="!ingredient.ref.id" class="form-group form-inline" [ngbTooltip]="tooltipProportions" tooltipClass="dt-tooltip">
                              <span>{{inci.ref.name}}</span>
                              <sub class="dt-eukv" *ngIf="inci.ref.isEukvAppendix()">
                                <span *ngFor="let a of inci.ref.eukv.list; index as k">
                                  <span *ngIf="k>0">,&nbsp;</span>
                                  <span [ngbTooltip]="eukvLabels.label(a.appendix)" tooltipClass="dt-tooltip">{{a.appendix | roman}}</span>
                                </span>
                              </sub>
                              <span *ngIf="inci.ref.isEukvAppendix() && inci.ref.getRoute()">&nbsp;</span>
                              <sub class="dt-inci-route" *ngIf="inci.ref.getRoute()">
                                <span *ngFor="let r of inci.ref.getRouteInfo(); index as k">
                                  <span *ngIf="k>0">,&nbsp;</span>
                                  <span *ngIf="r" [ngbTooltip]="r.value + ': ' + inci.ref.getRoute(r.value).value + ' (' + inci.ref.getRoute(r.value).type + ')'" tooltipClass="dt-tooltip">{{r.short}}</span>
                                  <span *ngIf="!r" [ngbTooltip]="'Unknown route: ' + inci.ref.getRoute().value" tooltipClass="dt-tooltip">?</span>
                                </span>
                              </sub>
                          <span class="mx-2">
                                <input id="{{i}}-{{j}}-min" name="{{i}}-{{j}}-min" type="number" step="any" min="0" max="100" 
                                    [(ngModel)]="inci.percentMin" [disabled]="data.activeReport.published" appMultiRequire="{{i}}-{{j}}-max"
                                    class="form-control multiRequired" style="width:7rem" (input)="unfreeze()">
                              </span>
                              -
                              <span class="mx-2">
                                <input id="{{i}}-{{j}}-max" name="{{i}}-{{j}}-max" type="number" step="any" min="0" max="100" 
                                    [(ngModel)]="inci.percentMax" [disabled]="data.activeReport.published" appMultiRequire="{{i}}-{{j}}-min"
                                    class="form-control multiRequired" style="width:7rem" (input)="unfreeze()">
                              </span>
                              %
                              <span class="float-end">
                                <button type="button" (click)="removeInci(ingredient, inci, $event)"
                                    class="btn" ngbTooltip="Remove INCI" tooltipClass="dt-tooltip" container="body"><span class="oi oi-minus"></span></button>
                              </span>
                            </div>
                          </div>
                          <div *ngIf="!ingredient.ref.id">
                              <div class="form-inline"><div class="form-group">
                                <p class="input-group mb-0">
                                  <input name="inciName" type="text" [(ngModel)]="ingredient.newInciName" 
                                    [ngbTypeahead]="searchInci" data-container="body"
                                    [disabled]="data.activeReport.published" appToUpperCase appTrim appFormIgnore [form]="form" class="form-control">
                                  <span class="input-group-append" 
                                    ngbTooltip="Add INCI" tooltipClass="dt-tooltip" container="body">
                                    <button type="button" (click)="addInci(ingredient, $event)" class="btn"><span class="oi oi-plus"></span></button>
                                  </span>
                                </p>
                              </div></div>
                          </div>
                        </td>
                        <td>
                          <!-- proportions -->
                          <div class="form-inline" *ngIf="!data.activeReport.published"
                              [ngbTooltip]="tooltipProportions" tooltipClass="dt-tooltip">
                            <span class="me-2">
                              <input id="{{i}}-min" name="{{i}}-min" type="number" step="any" min="0" max="100" [(ngModel)]="ingredient.percentMin" appMultiRequire="{{i}}-max" 
                                  class="form-control multiRequired" style="width:7rem" (input)="unfreeze()">
                            </span>
                            -
                            <span class="mx-2">
                              <input id="{{i}}-max" name="{{i}}-max" type="number" step="any" min="0" max="100" [(ngModel)]="ingredient.percentMax" appMultiRequire="{{i}}-min" 
                                  class="form-control multiRequired" style="width:7rem" (input)="unfreeze()">
                            </span>
                            %
                          </div>
                          <div *ngIf="data.activeReport.published">{{ingredient.formatPercent('en')}}&nbsp;%</div>
                        </td>
                        <td>
                          <!-- actions -->
                          <button type="button" (click)="removeIngredient(ingredient, $event)" *ngIf="!data.activeReport.published" class="btn" 
                              ngbTooltip="Remove Raw Material" tooltipClass="dt-tooltip" container="body"><span class="oi oi-minus"></span></button>
                        </td>
                      </tr>
                      <tr *ngIf="ingredient.alternatives.length" class="alternatives">
                        <!-- alternative ingredients -->
                        <td></td>
                        <td colspan="2">
                          <span>alt: </span>
                          <span *ngFor="let alternative of ingredient.alternatives; index as k"><span *ngIf="k>0">, </span>
                            <a *ngIf="alternative.ref.id && !hasObsoleteIngredient(alternative)" [routerLink]="['/ingredients', alternative.ref.key]" class="nav-link">{{alternative.ref.getName()}}</a>
                            <span *ngIf="alternative.ref.id && hasObsoleteIngredient(alternative)" 
                              ngbTooltip="Raw material was deleted or updated" tooltipClass="dt-tooltip" container="body">{{alternative.ref.getName()}} </span>
                            <span *ngIf="!alternative.ref.id"
                              ngbTooltip="Raw material not yet saved" tooltipClass="dt-tooltip" container="body">{{alternative.ref.getName()}} </span>
                            <span *ngIf="alternative.ref.name && alternative.ref.name != alternative.ref.tradename">&nbsp;{{alternative.ref.tradename}}</span>
                            <span *ngIf="alternative.ref.manufacturer">&nbsp;({{alternative.ref.manufacturer}})</span>
                          </span>
                        </td>
                        <td></td>
                        <td>
                          <!-- actions -->
                          <button type="button" (click)="removeIngredientAlternatives(ingredient, $event)" *ngIf="!data.activeReport.published" class="btn small" 
                              ngbTooltip="Remove Raw Material Alternatives" tooltipClass="dt-tooltip" container="body"><span class="oi oi-minus"></span></button>
                        </td>
                      </tr>
                      <tr *ngIf="showAddAlternative == ingredient">
                        <!-- add ingredient alternative -->
                        <td></td>
                        <td>
                          <div class="form-inline"><div class="form-group w-100">
                            <p class="input-group mb-0 w-100">
                              <input name="ingredientAlternativeName" type="text" [(ngModel)]="ingredientAlternativeName"
                                [ngbTypeahead]="searchIngredient"
                                data-container="body"
                                appTrim appFormIgnore [form]="form"
                                (blur)="showAddAlternative = ingredientAlternativeName ? ingredient : null"
                                class="form-control">
                              <span class="input-group-append" 
                                ngbTooltip="Add Alternative to this Raw Material" tooltipClass="dt-tooltip" container="body">
                                <button type="button" (click)="addIngredientAlternative(ingredient, $event)" class="btn"><span class="oi oi-plus"></span></button>
                              </span>
                            </p>
                          </div></div>
                        </td>
                        <td colspan="3"></td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="data.activeReport.ingredients.length > 0">
                      <!-- proportions sum -->
                      <td></td>
                      <th colspan="2" class="text-end">
                        <span class="px-3">Sum:</span>
                      </th>
                      <th>
                        <span class="me-2">
                          <span class="px-3 d-inline-block" style="width:7rem">{{data.activeReport.ingredientsPercentMinTrim() | num2txt: 7:6 }}</span>
                        </span>
                        - 
                        <span class="mx-2">
                          <span class="px-3 d-inline-block" style="width:7rem">{{data.activeReport.ingredientsPercentMaxTrim() | num2txt: 7:6 }}</span>
                        </span>
                      </th>
                      <td></td>
                    </tr>
                    <tr *ngIf="!data.activeReport.published">
                      <!-- add ingredient -->
                      <td></td>
                      <td>
                        <div class="form-inline"><div class="form-group w-100">
                          <p class="input-group mb-0 w-100">
                            <input name="ingredientName" type="text" [(ngModel)]="ingredientName"
                              [ngbTypeahead]="searchIngredient" data-container="body"
                              appTrim appFormIgnore [form]="form" class="form-control">
                            <span class="input-group-append" 
                              ngbTooltip="Add Raw Material" tooltipClass="dt-tooltip" container="body">
                              <button type="button" (click)="addIngredient($event)" class="btn"><span class="oi oi-plus"></span></button>
                            </span>
                          </p>
                        </div></div>
                      </td>
                      <td colspan="3"></td>
                    </tr>
                  </tbody></table>
                </div>
              </div>
            </ng-template>
          </ngb-panel>

          <!-- Panel: Properties -->
          <ngb-panel id="panel-3">
            <ng-template ngbPanelTitle>
              <span class="oi oi-caret-right" [ngClass]="{'card-open': acc.panels._results[3].isOpen, 'valid': data.activeReport.isSectionValid(section.Properties)}"></span>&nbsp;
              <span [ngClass]="{'valid': data.activeReport.isSectionValid(section.Properties)}">Properties</span>
              <span class="float-end oi" [ngClass]="{'oi-check valid': data.activeReport.isSectionValid(section.Properties), 'oi-pencil': !data.activeReport.isSectionValid(section.Properties) }"></span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row" *ngIf="data.activeReport.template">
                <div class="col-md-6 form-group" *ngFor="let prop of data.activeReport.template.properties.docx">
                  <label for="date">{{prop.label}}*</label>
                  <input [disabled]="data.activeReport.published" 
                      id="{{prop.name}}" name="{{prop.name}}" type="{{prop.type}}"
                      [(ngModel)]="data.activeReport.data[prop.name]" #property="ngModel"
                      appMinValue="{{prop.min}}" class="form-control required"/>
                  <div [hidden]="property.valid || property.pristine || property.disabled" class="alert alert-danger">
                    <span *ngIf="prop.type === 'number'">{{prop.label}} is not a valid number</span>
                    <span *ngIf="prop.type !== 'number'">{{prop.label}} is required</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>

          <!-- Panel: Content -->
          <ngb-panel id="panel-4">
            <ng-template ngbPanelTitle>
              <span class="oi oi-caret-right" [ngClass]="{'card-open': acc.panels._results[4].isOpen, 'valid': data.activeReport.isSectionValid(section.Content)}"></span>&nbsp;
              <span [ngClass]="{'valid': data.activeReport.isSectionValid(section.Content)}">Report Content</span>
              <span class="float-end oi"
                *ngIf="!acc.panels._results[4].isOpen"
                [ngClass]="{'oi-check valid': data.activeReport.isSectionValid(section.Content), 'oi-pencil': !data.activeReport.isSectionValid(section.Content) }"></span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row" *ngIf="!data.activeReport.hasContent()">
                <div class="col-md-4">
                  <button 
                    [disabled]="data.activeReport.published || !data.activeReport.isSectionsValid([section.Basics,section.Users,section.Ingredients,section.Properties])" 
                    class="btn btn-primary" (click)="refreshText($event)">Refresh</button>
                </div>
                <div class="col-md-8">
                  <p>This action will generate the report body texts, based on the data above.</p>
                </div>
              </div>
              <div class="row" *ngIf="data.activeReport.hasContent()">
                <ngb-accordion class="w-100" #acc_chapters="ngbAccordion" [closeOthers]="false" [activeIds]="activeContentPanels">
                  <ngb-panel *ngFor="let block of data.activeReport.textblocks; index as i" id="contentpanel-{{i}}">
                    <ng-template ngbPanelTitle>
                      <span [ngClass]="{'valid': block.properties.frozen, 'invalid': block.alert}">{{block.label}}</span>
                      <span class="float-end oi" [ngClass]="{'oi-check valid': block.properties.frozen, 'oi-pencil': !block.properties.frozen && !block.alert, 'oi-warning invalid': block.alert}"></span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <!-- Actions: Freeze, Calculate, Re-Generate -->
                      <div class="text-end">
                        <button *ngIf="!data.activeReport.published" 
                              class="btn btn-light"
                              ngbTooltip="{{block.properties.frozen ? 'Reopen' : 'Freeze'}}" tooltipClass="dt-tooltip" container="body"
                              (click)="freezeBlock(block)">
                          <span class="oi" 
                              [ngClass]="{'oi-pin': !block.properties.frozen, 'oi-pencil': block.properties.frozen }"></span>
                        </button>
                        <button
                              [disabled]="block.properties.frozen"
                              class="btn btn-light ms-1" 
                              ngbTooltip="Recalculate all formulas" tooltipClass="dt-tooltip" container="body"
                              (click)="recalculate()">
                          <span class="oi oi-calculator"></span>
                        </button>
                        <button
                              [disabled]="block.properties.frozen"
                              class="btn btn-light ms-1" 
                              ngbTooltip="Re-generate this block" tooltipClass="dt-tooltip" container="body"
                              (click)="refreshBlock(block)">
                          <span class="oi oi-loop-square"></span>
                        </button>
                      </div>
                      <div *ngFor="let tb of block.childBlocks; index as j">
                        <!-- Editor -->
                        <ckeditor *ngIf="tb.isEditable()"
                            name="ckeditor-{{i}}-{{j}}" [editor]="editorFactory" #editor
                            [config]="editorConfig"
                            [(ngModel)]="tb.dataText"
                            [disabled]="block.properties.frozen"
                            (ready)="ngEditorOnReady($event)"
                            (change)="ngEditorOnChange($event, tb)">
                          </ckeditor>
                        <!-- Template Selection -->
                        <div class="container-fluid" *ngIf="tb.needsIncludeSelection()">
                          <div class="row">Select relevant optional blocks, then re-generate this chapter:</div>
                          <div *ngFor="let tname of tb.properties.typeProps.names; index as i" class="row">
                            <div class="form-check">
                              <input [attr.id]="'template-' + tb.properties.typeProps.id + '-' + i" name="template-{{tb.properties.typeProps.id}}-{{i}}" type="checkbox" class="form-check-input"
                                [(ngModel)]="data.activeReport.data.structure[tb.properties.typeProps.id][tname]">
                              <label [attr.for]="'template-' + tb.properties.typeProps.id + '-' + i" class="form-check-label">{{tb.properties.typeProps.labels[i]}}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
              <div class="row" *ngIf="data.activeReport.hasContent()">
                <div class="col-md-12 text-end">
                  <button 
                    class="btn btn-outline-secondary" 
                    (click)="discardContent()"
                    [disabled]="data.activeReport.published"> <span class="oi oi-x"></span> Discard Content</button>
                  &nbsp;
                  <button 
                    class="btn btn-outline-secondary" 
                    (click)="freeze()"
                    [disabled]="data.activeReport.published"><span class="oi oi-pin"></span> Freeze All</button>
                </div>
              </div>
            </ng-template>
          </ngb-panel>

          <!-- Panel: Actions -->
          <ngb-panel id="panel-5">
            <ng-template ngbPanelTitle>
              <span class="oi oi-caret-right" [ngClass]="{'card-open': acc.panels._results[5].isOpen, 'valid': data.activeReport.isSectionValid(section.DOCX)}"></span>&nbsp;
              <span [ngClass]="{'valid': data.activeReport.isSectionValid(section.DOCX)}">DOCX file output</span>
              <span class="float-end oi" 
                  [ngClass]="{'oi-check valid': data.activeReport.isSectionValid(section.DOCX), 'oi-pencil': !data.activeReport.isSectionValid(section.DOCX) }"></span>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>When you're done editing, you'll freeze the status and generate DOCX file.</p>
              <div class="row">
                <div class="col-md-6">
                  <div *ngIf="!data.activeReport.isValid()" class="alert alert-warning">Please complete the pending edit tasks above.</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button 
                    [disabled]="data.activeReport.published || !data.activeReport.isValid()" 
                    class="btn btn-primary" 
                    (click)="publish($event)"><span class="oi oi-document"></span> Generate</button>
                </div>
                <div class="col-md-2">
                  <button [disabled]="!data.activeReport.published" class="btn btn-primary" (click)="download($event)">
                    <span class="oi oi-data-transfer-download"></span> Download</button>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
          
        </ngb-accordion>
        
      </div>
      <app-confirm #confirm></app-confirm>
    </main>
    </form>
  </div>
</div>
