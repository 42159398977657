import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DataService, SpinnerService, NotifyService, ComponentDeactivate, BaseEditpage } from '../../common';
import { BaseMainpage, Constants, ConfirmComponent, HtmlUtil } from '../../common';
import { Ingredient, IngredientInci, Inci, Specification } from '../../common/model';
import { IngredientService, InciService, SpecificationService } from '../../common/services';
import { InciEditComponent } from '../inci';
import { NgForm } from '@angular/forms';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ingredient-edit',
  templateUrl: './ingredient-edit.component.html'
})
export class IngredientEditComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @Input() ingredient: Ingredient;

  @ViewChild('f', { static: true }) form: NgForm;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  public languages = Constants.LANG;
  public inciName: string;
  public newInci: Inci;
  public specs = {
    list: []
  };

  private editpage: BaseEditpage<Ingredient> = null;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private sanitizer: DomSanitizer,
      private modalService: NgbModal,
      private ingredientService: IngredientService,
      private inciService: InciService,
      private specificationService: SpecificationService) {
    super(spinner, notify, router, data);
    console.log('IngredientEditComponent.create()');
  }

  ngOnInit() {
    console.log('IngredientEditComponent.init()');
    if (this.data.ingredients.duplicatedEntity) {
      this.ingredient = this.data.ingredients.duplicatedEntity;
      this.data.ingredients.duplicatedEntity = null;
    }
    this.editpage = new BaseEditpage(this, this.form, this.confirm, this.ingredientService, this.ingredient);
    this.editpage._postCreate = (i: Ingredient) => {
      this.router.navigate(['/ingredients', i.key]);
    };
    this.editpage._preSave = () => {
      this.ingredient.specifications = this.specs;
    };
    this.editpage._validate = () => this.ingredient.incis.length > 0;
    this._loadAll();
  }

  canDeactivate() {
    return this.editpage.canDeactivate();
  }

  save(): Promise<void> {
    return this.editpage.save();
  }

  searchInci: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const names = () => this.data.incis.names.all || [];
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : names()
          .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort((a, b) => a.length - b.length)
          .slice(0, 10))
  )};

  addInci(event: Event) {
    event.preventDefault();
    if (this.inciName) {
      this.inciService.getByName(this.inciName)
        .then(i => {
          if (i) {
            const ii = new IngredientInci();
            ii.ref = i;
            ii.inciKey = i.key;
            this.ingredient.incis.push(ii);
            this.inciName = null;
          } else {
            this.newInci = new Inci();
            this.newInci.name = this.inciName;
            const modalRef = this.modalService.open(InciEditComponent, {
              centered: true,
              size: 'lg',
              backdrop: 'static'
            });
            modalRef.componentInstance.inci = this.newInci;
            modalRef.componentInstance.language = this.ingredient.language;
            modalRef.result
              .then(() => {
                  // Closed by button, do nothing
                }, () => {
                  // dismissed
                  if (!this.newInci.id) {
                    return this.confirm.open('New INCI', 'Save INCI: ' + this.newInci.name + ' ?', 'Save');
                  }
                })
              .then(() => {
                if (!this.newInci.id) {
                  console.log('IngredientEditComponent.addInci()', 'Create INCI', this.newInci);
                  return this.inciService.create(this.newInci)
                    .catch(err => {
                      this.notify_error('IngredientEditComponent.addInci()', err);
                      return Promise.reject();
                    });
                } else {
                  return this.newInci;
                }
              })
              .then(newInci => {
                const ii = new IngredientInci();
                ii.ref = newInci;
                this.ingredient.incis.push(ii);
                this.inciName = null;
              })
              .catch(() => { /* do nothing */ })
              .finally(() => {
                this.newInci = null;
                modalRef.dismiss();
              });
          }
        });
    }
  }

  removeInci(inci: IngredientInci, event: Event) {
    event.preventDefault();
    this.ingredient.incis = this.ingredient.incis.filter(ii => ii !== inci);
    this.form.form.markAsDirty();
  }

  text2html(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(HtmlUtil.text2html(text));
  }

  private _loadAll() {
    console.log('IngredientEditComponent._loadAll()');
    this.startSpinner();
    Promise.all([,
      this.specificationService.assertLoaded()
    ])
      .then(() => {
        this.specs = { list: [] };
        this.data.specifications.sort((a: Specification, b: Specification) => a.sortRank - b.sortRank);
        this.data.specifications.list.forEach(spec => {
          this.specs.list.push({
            specificationKey: spec.key,
            ref: spec,
            value: this.ingredient ? this.ingredient.getSpecificationValue(spec.key) : null
          });
        });
      })
      .catch(err => {
          console.log('IngredientEditComponent._loadAll()', 'Failed to load dependencies: ', err);
      })
      .finally(() => {
        this.stopSpinner();
      });
  }

}
