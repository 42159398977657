import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
// import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import { NgForm } from '@angular/forms';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Report, ReportSection, Textblock, Ingredient, ReportIngredient, Inci, IngredientInci, Template } from '../common/model';
import { DataService, AuthenticationService, AlertService, SpinnerService, NotifyService, ComponentDeactivate } from '../common';
import { BaseMainpage, Constants, ConfirmComponent } from '../common';
import { CustomerService, UserService, ReportService } from '../common/services';
import { TemplateService, InciService, FunctionService, IngredientService } from '../common/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InciEditComponent } from '../masterdata/inci';
import { TemplateProcessor } from './template.processor';
import { TemplateTokenizer } from './template.tokenizer';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { IngredientAlternative } from '../common/model/ingredient_alternative.model';

@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html'
})
export class ReportEditComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild('form') form: NgForm;
  @ViewChild('confirm') confirm: ConfirmComponent;
  @ViewChild('editor') editor: CKEditorComponent;

  public applications = Constants.APPLICATIONS.all();
  public languages = Constants.LANG;
  public eukvLabels = Constants.EUKV_APPENDIX;
  public blockTypes = Textblock.Type;
  public editorFactory = InlineEditor;
  public section = ReportSection;

  public ingredientName: string;
  public ingredientAlternativeName: string;
  public showAddAlternative: ReportIngredient;
  public newInci: Inci;
  public activeContentPanels: string[] = [];

  public editorConfig = {
    heading: {
      options: [
        { model: 'heading1', view: 'h1', title: 'Überschrift 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Überschrift 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Überschrift 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Überschrift 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Überschrift 5', class: 'ck-heading_heading5' },
        { model: 'heading6', view: 'h6', title: 'Überschrift 6', class: 'ck-heading_heading6' },
        { model: 'toc_heading', view: { name: 'div', classes: 'Überschrift_Inhalt_SIB' },
            title: 'Überschrift Inhalt', class: 'ck-heading_heading1 text-underlined' },
        { model: 'toc', view: { name: 'div', classes: 'TOC' }, title: 'Inhaltsverzeichnis', class: 'dt-toc' },
        { model: 'annotation', view: { name: 'div', classes: 'Annotation_SIB' }, title: 'Annotation', class: 'dt-annotation' },
        { model: 'sig_head', view: { name: 'div', classes: 'Signatur_Header_SIB' }, title: 'Signatur Name', class: 'dt-sig-head' },
        { model: 'sig_text', view: { name: 'div', classes: 'Signatur_SIB' }, title: 'Signatur Text', class: 'dt-sig-text' },
        { model: 'biblio', view: { name: 'div', classes: 'Literatur_SIB' }, title: 'Literatur', class: 'dt-biblio' },
        { model: 'quotation', view: { name: 'div', classes: 'dt-quotation' }, title: 'Quotation', class: 'ck-heading_paragraph' },
        { model: 'paragraph', title: 'Standard', class: 'ck-heading_paragraph' }
      ]
    }
  };

  constructor(
    spinner: SpinnerService,
    notify: NotifyService,
    router: Router,
    data: DataService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private customerService: CustomerService,
    private userService: UserService,
    private reportService: ReportService,
    private templateService: TemplateService,
    private inciService: InciService,
    private ingredientService: IngredientService,
    private functionService: FunctionService) {

    super(spinner, notify, router, data);
    console.log('ReportEditComponent.create()');
  }

  ngOnInit() {
    console.log('ReportEditComponent.onInit()');
    this.route.params.subscribe(params => {
      this._initReport(params['key'])
      .then(() => {
        if (this.data.activeReport.template) {
          this.templateChanged();
        }
      })
      .then(() => {
        if (this.form) {
          this.data.activeReport.dirty ? this.form.form.markAsDirty() : this.form.form.markAsPristine();
        }
      });
    });

    this.startSpinner();
    Promise.all([
      this.customerService.assertLoaded(),
      this.userService.assertLoaded(),
      this.templateService.assertLoaded(),
      this.functionService.assertLoaded(),
      this.inciService.assertLoaded(),
      this.ingredientService.assertLoaded()
    ])
      .finally(() => {
        this.stopSpinner();
      });
  }

  canDeactivate() {
    console.log('ReportEditComponent.canDeactivate()');
    if (this.data.activeReport && this.form.dirty) {
      return this.confirm.open('Report', 'Save Report: ' + this.data.activeReport.title + ' ?', 'Save')
        .then(
          () => { this.save(); },               // Option: Save
          () => { this.data.activeReport.dirty = true; }   // Option: Dismiss;
        )
        .then(() => true )
        .catch(() => true );
    }
    return true; // false stops navigation, true continue navigation
  }

  public ngEditorOnReady(editor) {
    // console.log('ReportEditComponent.ngEditorOnReady()', 'Created editor component');
    // CKEditorInspector.attach( editor );
  }
  public ngEditorOnChange({editor}, tb: Textblock) {
    this.form.form.markAsDirty();
  }

  languageChanged() {
    console.log('ReportEditComponent.languageChanged()', this.data.activeReport.language);
    this.data.activeReport.template = null;
    if (this.data.templates.roots(this.data.activeReport.language).length === 1) {
      this.data.activeReport.template = this.data.templates.roots(this.data.activeReport.language)[0];
      this.templateChanged();
    }
  }

  templateChanged() {
    if (!this.data.activeReport.data) {
      this.data.activeReport.data = {};
    }
    if (this.data.activeReport.template.properties.docx) {
      // refresh properties section in UI
      this.data.activeReport.template.properties.docx.forEach(prop => {
        if (this.data.activeReport.data[prop.name] == null || this.data.activeReport.data[prop.name] === undefined) {
          this.data.activeReport.data[prop.name] = prop.default ? prop.default : null;
          if (!prop.default && prop.type === 'date') {
            this.data.activeReport.data[prop.name] = new Date().toISOString().slice(0, 10);
          }
        }
      });
    }
    if (this.data.activeReport.templateId) {
      this.templateService.getById(this.data.activeReport.templateId)
        .then(c => {
            // unfreeze only if the root template key changed
            if (c.key !== this.data.activeReport.template.key) {
            this.unfreeze();
          }
        });
    }
    this.data.activeReport.templateId = this.data.activeReport.template.id;
    console.log('ReportEditComponent.templateChanged()', this.data.activeReport.data);
  }

  customerChanged() {
    console.log('ReportEditComponent.customerChanged()', this.data.activeReport.customer.name);
    if (this.data.activeReport.customerId) {
      this.customerService.getById(this.data.activeReport.customerId)
        .then(c => {
            // unfreeze only if the customer key changed
            if (c.key !== this.data.activeReport.customer.key) {
              this.unfreeze();
            }
        });
    }
    this.data.activeReport.customerId = this.data.activeReport.customer.id;
    // also set selected sponsor as manufacturer, if that's still empty
    if (!this.data.activeReport.manufacturer) {
      this.data.activeReport.manufacturer = this.data.activeReport.customer;
      this.data.activeReport.manufacturerId = this.data.activeReport.customer.id;
    }
  }

  bottlerChanged() {
    console.log('ReportEditComponent.bottlerChanged()', this.data.activeReport.bottler.name);
    if (this.data.activeReport.bottlerId) {
      this.customerService.getById(this.data.activeReport.bottlerId)
        .then(b => {
            // unfreeze only if the customer key changed
            if (b.key !== this.data.activeReport.bottler.key) {
              this.unfreeze();
            }
        });
    }
    this.data.activeReport.bottlerId = this.data.activeReport.bottler.id;
  }

  distributorChanged() {
    console.log('ReportEditComponent.distributorChanged()', this.data.activeReport.distributor.name);
    if (this.data.activeReport.distributorId) {
      this.customerService.getById(this.data.activeReport.distributorId)
        .then(d => {
            // unfreeze only if the customer key changed
            if (d.key !== this.data.activeReport.distributor.key) {
              this.unfreeze();
            }
        });
    }
    this.data.activeReport.distributorId = this.data.activeReport.distributor.id;
  }

  manufacturerChanged() {
    console.log('ReportEditComponent.manufacturerChanged()', this.data.activeReport.manufacturer.name);
    if (this.data.activeReport.manufacturerId) {
      this.customerService.getById(this.data.activeReport.manufacturerId)
        .then(c => {
            // unfreeze only if the manufacturer key changed
            if (c.key !== this.data.activeReport.manufacturer.key) {
              this.unfreeze();
            }
        });
    }
    this.data.activeReport.manufacturerId = this.data.activeReport.manufacturer.id;
  }

  save(event?: Event) {
    console.log('ReportEditComponent.save()', this.data.activeReport);
    if (event) { event.preventDefault(); }
    this.alertService.clear();
    if (!this.data.activeReport.isSectionValid(ReportSection.Basics)
      || !this.data.activeReport.isSectionValid(ReportSection.Users)) {
      this.alertService.warning('Please fill at least the first two sections before saving');
    } else if (this.data.activeReport.ingredients.filter(ri => ri.ref.id == null && ri.ref.incis.length === 0).length) {
      this.alertService.warning('Add INCIs to new Ingredient before saving: '
        + this.data.activeReport.ingredients
            .filter(ri => ri.ref.id == null && ri.ref.incis.length === 0)
            .map(ri => ri.ref.name)
            .join(', ')
          );
    } else {
        if (this.isSpinnerActive()) {
          console.log('ReportEditComponent.save()', 'update operation already running, skipping');
          return;
        }
        if (this.form.dirty) {
        // mark everything as clean to avoid a confirmation dialog in case of a new report.
        this.data.activeReport.dirty = false;
        this.form.form.markAsPristine();
        const scrollYOffset = window.scrollY;
        this.startSpinner();
        this._saveReport()
          .then(() => this.notify_ok())
          .catch(err => {
            this.data.activeReport.dirty = true;
            this.form.form.markAsDirty();
            this.notify_error('ReportEditComponent.save()', err)
          })
          .finally(() => {
            this.stopSpinner();
            window.setTimeout(() => {
              console.log('ReportEditComponent.save()', 'Retain previous Y scroll offset: ', scrollYOffset);
              window.scrollTo({
                top: scrollYOffset,
                left: 0,
                behavior: "instant",
              });
            }, 0);
          });
      }
    }
  }

  freezeBlock(block: Textblock) {
    block.properties.frozen = !block.properties.frozen;
    setTimeout(() => {
      this.form.form.markAsDirty();
    }, 0);
  }

  refreshBlock(block: Textblock) {
    // block argument is element of condensed textblocks array
    this.confirm.open('Report Content', 'Discard this block and re-generate the content?', 'Generate')
      .then(() => {
        console.log('ReportEditComponent.refreshBlock()');
        this.startSpinner();

        const panel_index = this.data.activeReport.textblocks.indexOf(block);
        let template = null;
        // find template for generation
        const sourceBlock = this.data.activeReport.contentTree.findChild(block.uuid);
        sourceBlock.iteratePreorder(b => {
          if (!template && b.properties.type === 'template') {
            template = b.templateRef;
          }
        });
        if (template && template.obsolete) {
          console.log("replacing obsolete template - id: ",  template.id, "; key: ", template.key, " - ", template.obsolete);
          this.templateService.loadByKey(template.key).then(t => {
            template = t;
            console.log("replaced obsolete template - id: ",  template.id, "; key: ", template.key, " - ", template.obsolete);
            this.refreshBlockInternal(block, template, sourceBlock, panel_index);
          });
        } else {
          this.refreshBlockInternal(block, template, sourceBlock, panel_index);
        }
        })
        .catch(() => { /* do nothing */ });
  }

  refreshBlockInternal(block: Textblock, template: Template, sourceBlock: Textblock, panel_index: number) {
    // if the panel rendered a selection of includes, then this is the processing of the user's choice
    block.iteratePreorder(b => {
      if (b.needsIncludeSelection()) {
        this.data.activeReport.data.structure[b.properties.typeProps.id]._confirmed_ = true;
      }
    });
    // trigger content generation
    const t = new TemplateTokenizer(template.dataText);
    const c = new TemplateProcessor(this.templateService, template, this.data.activeReport, null, true);
    this.async()
      .then(() => t.process(c))
      .then(() => {
        // process new content blocks
        c.rootBlock.resolveDatafields();
        c.rootBlock.resolveProperties();
        c.rootBlock.resolveCalculations();
        c.rootBlock.resolveDynamicBlocks();
        // insert new content into contentTree and textblocks list
        this.data.activeReport.contentTree.replaceChild(sourceBlock.uuid, c.rootBlock);
        this.data.activeReport.textblocks[panel_index] = c.rootBlock.asUiCondensedBlock();
        return this.data.activeReport.textblocks[panel_index];
      })
      .then(b => {
        // check if already frozen
        b.properties.frozen = b.childBlocks.reduce((flag, tb) => flag && tb.properties.frozen, true);
        this.activeContentPanels = ['contentpanel-' + panel_index];
      })
      .then(() => this.form.form.markAsDirty())
      .catch(err => this.notify_error('ReportEditComponent.refreshBlock()', err))
      .finally(() => this.stopSpinner());
  }

  hasObsoleteIngredients(): string[] {
    return this.data.activeReport.ingredients
        .reduce((list: Array<ReportIngredient | IngredientAlternative>, ri) => {
          list.push(ri);
          ri.alternatives.forEach(ia => {
            list.push(ia);  // IngredientAlternative may safely be ducktyped as ReportIngredient here: It has identical 'ingredientId' and 'ref' attributes.
          })
          return list;
        }, [])
        .filter(ri => this.hasObsoleteIngredient(ri))
        .map(ri => ri.ref.getName());
  }

  hasObsoleteIngredient(ri: ReportIngredient | IngredientAlternative) {
    return ri.ref && ri.ref.id && (
        !this.data.ingredients.isActive(ri.ref)
        || ri.ref.id !== ri.ingredientId);
  }

  hasAnyObsoleteCustomerRef() {
    return this.hasObsoleteCustomer()
      || this.hasObsoleteManufacturer()
      || this.hasObsoleteBottler()
      || this.hasObsoleteDistributor();
  }

  hasObsoleteCustomer() {
    return this.data.activeReport.customer
        && (!this.data.customers.isActive(this.data.activeReport.customer)
        || this.data.activeReport.customer.id !== this.data.activeReport.customerId);
  }

  hasObsoleteManufacturer() {
    return this.data.activeReport.manufacturer
        && (!this.data.customers.isActive(this.data.activeReport.manufacturer)
        || this.data.activeReport.manufacturer.id !== this.data.activeReport.manufacturerId);
  }

  hasObsoleteBottler() {
    return this.data.activeReport.bottler
        && (!this.data.customers.isActive(this.data.activeReport.bottler)
        || this.data.activeReport.bottler.id !== this.data.activeReport.bottlerId);
  }

  hasObsoleteDistributor() {
    return this.data.activeReport.distributor
        && (!this.data.customers.isActive(this.data.activeReport.distributor)
        || this.data.activeReport.distributor.id !== this.data.activeReport.distributorId);
  }

  hasObsoleteTemplate() {
    return this.data.activeReport.template
        && (!this.data.templates.isActive(this.data.activeReport.template)
        || this.data.activeReport.template.id !== this.data.activeReport.templateId);
  }

  refreshIngredients() {
    console.log('ReportEditComponent.refreshIngredients()');
    this.data.activeReport.ingredients.forEach(ri => {
      if (ri.ref && this.data.ingredients.findByKey(ri.ref.key)) {
        ri.ref = this.data.ingredients.findByKey(ri.ref.key);
        ri.ingredientId = ri.ref.id;
      }
      ri.alternatives.forEach(ia => {
        if (ia.ref && this.data.ingredients.findByKey(ia.ref.key)) {
          ia.ref = this.data.ingredients.findByKey(ia.ref.key);
          ia.ingredientId = ia.ref.id;
        }
      });
    });
    this.form.form.markAsDirty();
  }

  refreshAllCustomerRefs() {
    this.refreshCustomer();
    this.refreshManufacturer();
    this.refreshBottler();
    this.refreshDistributor();
  }

  refreshCustomer() {
    console.log('ReportEditComponent.refreshCustomer()');
    if (this.data.activeReport.customer && this.data.customers.findByKey(this.data.activeReport.customer.key)) {
      this.data.activeReport.customer = this.data.customers.findByKey(this.data.activeReport.customer.key);
      this.data.activeReport.customerId = this.data.activeReport.customer.id;
    }
    this.form.form.markAsDirty();
  }

  refreshManufacturer() {
    console.log('ReportEditComponent.refreshManufacturer()');
    if (this.data.activeReport.manufacturer && this.data.customers.findByKey(this.data.activeReport.manufacturer.key)) {
      this.data.activeReport.manufacturer = this.data.customers.findByKey(this.data.activeReport.manufacturer.key);
      this.data.activeReport.manufacturerId = this.data.activeReport.manufacturer.id;
    }
    this.form.form.markAsDirty();
  }

  refreshBottler() {
    console.log('ReportEditComponent.refreshBottler()');
    if (this.data.activeReport.bottler && this.data.customers.findByKey(this.data.activeReport.bottler.key)) {
      this.data.activeReport.bottler = this.data.customers.findByKey(this.data.activeReport.bottler.key);
      this.data.activeReport.bottlerId = this.data.activeReport.bottler.id;
    }
    this.form.form.markAsDirty();
  }

  refreshDistributor() {
    console.log('ReportEditComponent.refreshCustomer()');
    if (this.data.activeReport.distributor && this.data.customers.findByKey(this.data.activeReport.distributor.key)) {
      this.data.activeReport.distributor = this.data.customers.findByKey(this.data.activeReport.distributor.key);
      this.data.activeReport.distributorId = this.data.activeReport.distributor.id;
    }
    this.form.form.markAsDirty();
  }

  refreshTemplate() {
    console.log('ReportEditComponent.refreshTemplate()');
    if (this.data.activeReport.template && this.data.templates.findByKey(this.data.activeReport.template.key)) {
      this.data.activeReport.template = this.data.templates.findByKey(this.data.activeReport.template.key);
      this.data.activeReport.templateId = this.data.activeReport.template.id;
    }
    this.form.form.markAsDirty();
  }

  freeze() {
    if (this.data.activeReport.textblocks) {
      this.data.activeReport.textblocks.forEach(b => {
        b.properties.frozen = true;
      });
      setTimeout(() => {
        this.form.form.markAsDirty();
      }, 0);
    }
  }

  unfreeze() {
    console.log('ReportEditComponent.unfreeze()');
    if (this.data.activeReport.textblocks) {
      let didReset = false;
      this.data.activeReport.textblocks.forEach(b => {
        // //does not work because the template references are lost
        // if (b.templateRef) {
        //   b.properties.frozen = Boolean(b.templateRef.properties.autoFreeze);
        // } else {
        //   this.templateService.getById(b.templateId).then(t => {
        //       b.properties.frozen = Boolean(t.properties.autoFreeze);
        //     }
        //   );
        // }
        didReset = b.properties.frozen === true;
        b.properties.frozen = false;
      });
      if (didReset) {
        this.alertService.info('Did reset report content\'s freeze state.');
      }
      setTimeout(() => {
        this.form.form.markAsDirty();
      }, 0);
    }
  }

  recalculate() {
    console.log('ReportEditComponent.recalculate()');
    this.startSpinner();
    setTimeout(() => {
      this.data.activeReport.contentTree.collectedDataCtx().reset();
      this.data.activeReport.contentTree.resolveDatafields();
      this.data.activeReport.contentTree.collectProperties();
      this.data.activeReport.contentTree.refreshCalculations();
      this.data.activeReport.contentTree.refreshDynamicBlocks();
      this.stopSpinner();
      this.form.form.markAsDirty();
    }, 0);
  }

  refreshText(event: Event) {
    console.log('ReportEditComponent.refreshText()');
    event.preventDefault();

    this.startSpinner();
    this.data.activeReport.textblocks = [];

    const t = new TemplateTokenizer(this.data.activeReport.template.dataText);
    const c = new TemplateProcessor(this.templateService, this.data.activeReport.template, this.data.activeReport);
    this.async()
      .then(() => t.process(c))
      .then(() => {
        // convert content tree into 2-level-deep list for UI presentation
        c.rootBlock.resolveDatafields();
        c.rootBlock.resolveProperties();
        c.rootBlock.resolveCalculations();
        c.rootBlock.resolveDynamicBlocks();
        this.data.activeReport.contentTree = c.rootBlock;
        this.data.activeReport.textblocks = c.rootBlock.asUiCondensedList();
        /*
        console.log('ReportEditComponent.refreshText()', '---- Content Tree ----');
        this.data.activeReport.contentTree.iteratePreorder((b, pos) => {
          console.log('ReportEditComponent.refreshText()', pos, b.signature());
        });
        console.log('ReportEditComponent.refreshText()', '---- UI Condensed Blocks ----');
        this.data.activeReport.textblocks.forEach(block => {
          block.iteratePreorder((b, pos) => {
            console.log('ReportEditComponent.refreshText()', pos, b.signature());
          });
        });
        */
      })
      .then(() => {
        // check for already frozen chapters
        this.data.activeReport.textblocks.forEach(chapter => {
          chapter.properties.frozen = chapter.childBlocks.reduce((flag, tb) => flag && tb.properties.frozen, true);
        });
      })
      .then(() => this.form.form.markAsDirty())
      .catch(err => this.notify_error('ReportEditComponent.refreshText()', err))
      .finally(() => this.stopSpinner());
  }

  exportSelection() {
    // !! Experimental code, not finished
    if (this.editor && this.editor.editorInstance) {
      console.log('ReportEditComponent.exportSelection()', 'Looking for current selection: ', this.editor);
      const dataProc = this.editor.editorInstance.data.processor;
      const selection = this.editor.editorInstance.model.document.selection;

      this.data['editor'] = this.editor.editorInstance;
      this.data['editorSelection'] = selection;
      console.log('ReportEditComponent.exportSelection()', 'Current selection: ', selection);
      if (selection) {
        let result = '';
        for ( const item of selection.getFirstRange().getItems() ) {
          console.log('ReportEditComponent.exportSelection()', 'Selection item: ', item);
          result += item.data ? item.data : dataProc.toData(item);
        }
        console.log('ReportEditComponent.exportSelection()', 'Current selection: ', result);
      }
    }
  }

  discardContent() {
    this.confirm.open('Report Content', 'Discard all content?', 'Discard')
      .then(() => {
        this.data.activeReport.textblocks = [];
        this.data.activeReport.contentTree = null;
        if (!this.data.templates.isActive(this.data.activeReport.template)) {
          this.data.activeReport.template = null;
        }
        this.activeContentPanels = [];
      })
      .then(() => this.form.form.markAsDirty())
      .catch(() => { /* do nothing */ });
  }

  publish(event: Event) {
    if (this.isSpinnerActive()) {
      console.log('ReportEditComponent.save()', 'update operation already running, skipping');
      return;
    }
    event.preventDefault();
    this.startSpinner();
    this._saveReport()
      .then(r => this.reportService.publish(r))
      .then(() => this.form.form.markAsPristine())
      .then(() => this.notify_ok())
      .catch(err => this.notify_error('ReportEditComponent.publish()', err))
      .finally(() => this.stopSpinner());
  }

  reopen(event: Event) {
    if (event) { event.preventDefault(); }
    this.alertService.clear();
    if (!this.data.activeReport.isSectionValid(ReportSection.Basics)
      || !this.data.activeReport.isSectionValid(ReportSection.Users))
    {
      console.log('ReportEditComponent.reopen()', 'Report is invalid. Keep reopen as local operation');
      this.data.activeReport.published = null;
      this.async()
        .then(() => {
          this.form.form.markAsDirty();
        })
    } else {
      this.startSpinner();
      this.reportService.reopen(this.data.activeReport)
        .then(() => this.notify_ok())
        .catch(err => this.notify_error('ReportEditComponent.reopen()', err))
        .finally(() => this.stopSpinner());
    }
  }

  delete(event: Event) {
    event.preventDefault();
    this.confirm.open('Report', 'Delete this report?', 'Delete')
      .then(() => {
        this.startSpinner();
        this.reportService.delete(this.data.activeReport)
          .catch(err => this.notify_error('ReportEditComponent.delete()', err))
          .finally(() => {
            this.stopSpinner();
            this.data.activeReport = null;
            this.router.navigate(['/']);
          });
      })
      .catch(() => { /* do nothing */ });
  }

  duplicate(event: Event) {
    event.preventDefault();
    // clone current report
    const r = this.reportService._preSave(this.data.activeReport);
    r.id = null;
    r.key = null;
    r.published = null;
    r.productName = r.productName + ' (Copy)';
    r.dirty = true;
    this.data.activeReport = this.reportService._postRead(new Report(r));
    this.router.navigate(['/reports/edit']);
  }

  download(event: Event) {
    event.preventDefault();
    if (this.data.activeReport.published) {
      this.reportService.download(this.data.activeReport)
        .then(blob => {
          const fileURL = URL.createObjectURL(blob);

          const a: HTMLAnchorElement = document.createElement('a');
          document.body.appendChild(a);
          a.style.display = 'none';
          a.href = fileURL;
          if (this.data.activeReport.filename) {
            a.download = this.data.activeReport.filename + '.docx';
          } else {
            a.download = this.data.activeReport.title + '.docx';
          }
          a.click();
          window.URL.revokeObjectURL(fileURL);
        });
    }
  }

  searchIngredient: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const names = () => this.data.ingredients.names.all || [];
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : names()
          .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort((a, b) => a.length - b.length)
          .slice(0, 10))
    );
  }

  searchInci: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const names = () => this.data.incis.names.all || [];
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : names()
          .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort((a, b) => a.length - b.length)
          .slice(0, 10))
    );
  }

  addIngredient(event: Event) {
    event.preventDefault();
    if (this.ingredientName) {
      this.ingredientService.getByCaption(this.ingredientName)
        .then(i => {
          if (!i) {
            i = new Ingredient();
            i.tradename = this.ingredientName;
            i.language = this.data.activeReport.language;
          }
          return i;
        })
        .then((i) => {
          const ri = new ReportIngredient();
          ri.ref = i;
          ri.ingredientId = i.id;
          this.data.activeReport.ingredients.push(ri);
          if (!i.id) {
            // TODO: unknown ingredient, set focus on inci input
          }
          this.unfreeze();
        })
        .then(() => {
          this.ingredientName = null;
        })
        .then(() => this.form.form.markAsDirty());
    }
  }

  addIngredientAlternative(ri: ReportIngredient, event: Event) {
    event.preventDefault();
    if (this.ingredientAlternativeName) {
      console.log('ReportEditComponent.addIngredientAlternative()', 'Add ingredient alternative: ', ri, this.ingredientAlternativeName)
      this.ingredientService.getByCaption(this.ingredientAlternativeName)
        .then((i) => {
          if (i) {
            return Promise.resolve(i);
          }
          return Promise.reject();
        })
        .then((i) => {
          const ia = new IngredientAlternative();
          ia.ref = i;
          ia.ingredientId = i.id;
          ri.alternatives = ri.alternatives || [];
          ri.alternatives.push(ia);
          this.unfreeze();
        })
        .then(() => { 
          this.ingredientAlternativeName = null;
          this.showAddAlternative = null;
        })
        .then(
          () => { this.form.form.markAsDirty(); },
          () => { console.log('ReportEditComponent.addIngredientAlternative()', 'Ingredient not found: ' + this.ingredientAlternativeName); })
        .catch(() => { /* do nothing */ });
    }
  }

  addInci(ingredient: ReportIngredient, event: Event) {
    event.preventDefault();
    if (ingredient.newInciName) {
      this.inciService.getByName(ingredient.newInciName)
        .then(i => {
          if (i) {
            const ii = new IngredientInci();
            ii.ref = i;
            ingredient.ref.incis.push(ii);
            ingredient.newInciName = null;
          } else {
            this.newInci = new Inci();
            this.newInci.name = ingredient.newInciName;
            const modalRef = this.modalService.open(InciEditComponent, {
              centered: true,
              size: 'xl',
              backdrop: 'static'
            });
            modalRef.componentInstance.inci = this.newInci;
            modalRef.componentInstance.language = this.data.activeReport.language;
            modalRef.result
              .then(() => {
                  // Closed by button, do nothing
                }, () => {
                  // dismissed
                  if (!this.newInci.id) {
                    return this.confirm.open('New INCI', 'Save INCI: ' + this.newInci.name + ' ?', 'Save');
                  }
                })
              .then(() => {
                if (!this.newInci.id) {
                  console.log('ReportEditComponent.addInci()', 'Create INCI', this.newInci);
                  return this.inciService.create(this.newInci)
                    .catch(err => {
                      this.notify_error('ReportEditComponent.addInci()', err);
                      return Promise.reject();
                    });
                } else {
                  return this.newInci;
                }
              })
              .then(newInci => {
                const ii = new IngredientInci();
                ii.ref = newInci;
                ingredient.ref.incis.push(ii);
                ingredient.newInciName = null;
              })
              .then(() => this.form.form.markAsDirty())
              .catch(() => { /* do nothing */ })
              .finally(() => {
                this.newInci = null;
                modalRef.dismiss();
              });
          }
        });
    }
  }

  removeInci(ingredient: ReportIngredient, inci: IngredientInci, event: Event) {
    event.preventDefault();
    ingredient.ref.incis = ingredient.ref.incis.filter(ii => ii !== inci);
    this.form.form.markAsDirty();
  }

  removeIngredient(ingredient: ReportIngredient, event: Event) {
    event.preventDefault();
    if (ingredient) {
      this.data.activeReport.ingredients = this.data.activeReport.ingredients.filter(i => i !== ingredient);
    }
    this.form.form.markAsDirty();
    this.unfreeze();
  }

  removeIngredientAlternatives(ingredient: ReportIngredient, event: Event) {
    event.preventDefault();
    if (ingredient) {
      ingredient.alternatives = [];
    }
    this.form.form.markAsDirty();
    this.unfreeze();
  }


  // ---------- private utils ----------

  private _initReport(key: string): Promise<void> {
    console.log('ReportEditComponent.initReport()', key);
    if (key === 'new') {
      this.data.activeReport = new Report();
      if (this.authService.currentUser().author) {
        this.data.activeReport.author = this.authService.currentUser();
      }
      if (this.data.templates.list) {
        this.data.activeReport.template = this.data.templates.list[0];
      }
      this.data.activeReport.language = 'de';
      this.data.activeReport.title = 'Sicherheitsbericht';
      this.data.activeReport.application = Constants.APPLICATIONS.LeaveOn.value;
    } else if (key === 'edit') {
      if (this.data.activeReport == null) {
        this.router.navigate(['/reports/new']);
        return Promise.reject();
      }
    } else {
      if (this.data.activeReport == null || this.data.activeReport.key !== +key) {
        return this.reportService.getByKey(+key)
          .then(r => this.reportService.loadDetails(r))
          .then(r => {
            this.data.activeReport = r;
          })
          .finally(() => {
            if (!this.data.activeReport) {
              this.router.navigate(['/']);
            }
          });
      }
    }
    return Promise.resolve();
  }

  private _saveReport(): Promise<Report> {
    return Promise.all(
      // save any non-persisted ingredients first
      this.data.activeReport.ingredients
        .filter(ri => {
          return ri.ref.id == null;
        })
        .map(ri => {
          return this.ingredientService.create(ri.ref);
        })
      )
      .then(() => {
        // now save the report
        if (!this.data.activeReport.id) {
          console.log('ReportEditComponent._saveReport()', 'create new report');
          return this.reportService.create(this.data.activeReport)
            .then(r => {
              this.data.activeReport.dirty = false;
              this.router.navigate(['/reports', r.key]);
              return r;
            });
        } else {
          console.log('ReportEditComponent._saveReport()', 'update existing report');
          return this.reportService.update(this.data.activeReport);
        }
      });
  }

}
