import { Ingredient } from './ingredient.model';
import { BasePortion } from './base_portion.model';
import { IngredientAlternative } from './ingredient_alternative.model';

export class ReportIngredient extends BasePortion {
    ingredientId: number;
    ref: Ingredient;
    newInciName: string;
    alternatives: IngredientAlternative[];

    constructor(source?: ReportIngredient) {
        super(source);
        this.alternatives = this.alternatives || [];
    }
}
