import { Observable } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanDeactivateFn } from '@angular/router';
import { inject, Injectable } from '@angular/core';

export interface ComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DeactivateGuardService  {

  canDeactivate(component: ComponentDeactivate,
           route: ActivatedRouteSnapshot,
           state: RouterStateSnapshot) {
    console.log('DeactivateGuard.canDeactivate()', 'Leaving route: ', state.url, component);
    return component.canDeactivate ? component.canDeactivate() : true;
  }

}

export const DeactivateGuard: CanDeactivateFn<ComponentDeactivate> = (component: ComponentDeactivate,
      currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {
  return inject(DeactivateGuardService).canDeactivate(component, currentRoute, currentState);
}
