import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Report, Result, Role, Textblock, ReportIngredient, ReportUser, ProductTag } from '../model';
import { DataService } from '../data.service';
import { CustomerService } from './customer.service';
import { UserService } from './user.service';
import { TemplateService } from './template.service';
import { IngredientService } from './ingredient.service';
import { EntityStore } from './entity_store';
import { ReportLightService } from './reportlight.service';
import { IngredientAlternative } from '../model/ingredient_alternative.model';

@Injectable()
export class ReportService extends EntityStore<Report> {

    constructor(
        http: HttpClient,
        dataService: DataService,
        customerService: CustomerService,
        userService: UserService,
        templateService: TemplateService,
        ingredientService: IngredientService,
        private reportLightService: ReportLightService) {
            super(http, dataService.reports, '/api/reports', Report, false);

            this._loadDependencies = () => {
                return Promise.all([
                    customerService.assertLoaded(),
                    userService.assertLoaded(),
                    templateService.assertLoaded(),
                    ingredientService.assertLoaded()
                ]).then(() => null);
            };

            this._preSave = (report: Report) => {
                report = super._preSave(report);
                report.customerId = report.customer.id;
                report.manufacturerId = report.manufacturer.id;
                if (report.bottler) {
                    report.bottlerId = report.bottler.id;
                }
                if (report.distributor) {
                    report.distributorId = report.distributor.id;
                }
                // fill users array
                var idx = 1;
                report.users = [];
                report.users.push({ userId: report.author.id, role: Role.Author, sortRank: idx++ });
                report.coAuthors.forEach(ca => {
                    report.users.push({ userId: ca.id, role: Role.CoAuthor, sortRank: idx++ });
                });
                // set relation reference id fields
                report.templateId = report.template.id;
                if (report.ingredients) {
                    report.ingredients.forEach(ri => {
                        ri.ingredientId = ri.ref.id;
                        ri.newInciName = null;
                        ri.alternatives.forEach(ia => {
                            ia.ingredientId = ia.ref.id;
                        })
                    });
                }
                const flatTextblocks = [];
                if (report.contentTree) {
                    report.contentTree.iteratePreorder((tb, pos) => {
                        tb.templateId = tb.templateRef ? tb.templateRef.id : null;
                        tb.position = pos;
                        tb.dataCtx = null;
                        tb.parent = null;
                        flatTextblocks.push(tb);
                    });
                }

                // clone object before stripping redundant structure data
                // keep textblocks out of the way, their parent back-ref causes cyclic ref errors on JSON serialization
                const _buf: [Textblock, Textblock[]] = [report.contentTree, report.textblocks];
                report.contentTree = null;
                report.textblocks = null;
                const r = JSON.parse(JSON.stringify(report));
                report.contentTree = _buf[0];
                report.textblocks = _buf[1];
                r.author = null;
                r.coAuthors = null;
                r.customer = null;
                r.template = null;
                if (r.ingredients) {
                    r.ingredients.forEach(ri => {
                        if (ri.ref.id) {
                            ri.ref = null;
                        }
                        ri.alternatives.forEach(ia => {
                            if (ia.ref.id) {
                                ia.ref = null;
                            }
                        })
                    });
                }
                r.textblocks = JSON.parse(JSON.stringify(flatTextblocks));
                r.textblocks.forEach(tb => {
                    tb.templateRef = null;
                    tb.childBlocks = [];
                });
                r.contentTree = null;
                return r;
            };

            this._postRead = (report: Report) => {
                report.data.ctx = report.data.ctx || {};
                if (report.customerId) {
                    customerService.getById(report.customerId)
                        .then(c => {
                            report.customer = c;
                        });
                }
                if (report.manufacturerId) {
                    customerService.getById(report.manufacturerId)
                        .then(m => {
                            report.manufacturer = m;
                        });
                }
                if (report.bottlerId) {
                    customerService.getById(report.bottlerId)
                        .then(b => {
                            report.bottler = b;
                        });
                }
                if (report.distributorId) {
                    customerService.getById(report.distributorId)
                        .then(d => {
                            report.distributor = d;
                        });
                }
                if (report.users) {
                    report.users.forEach((ru, idx) => {     // legacy support: sortRank might still be null
                        if (!ru.sortRank) {
                            ru.sortRank = idx+1;
                        }
                    })
                    report.users.sort((a, b) => a.sortRank - b.sortRank);
                    const ruAuthor = report.users
                        .find(ru => ru.role === Role.Author)
                    if (ruAuthor) {
                        userService.getById(ruAuthor.userId)
                            .then(u => userService.getByKey(u.key))
                            .then(u => {
                                report.author = u;
                            });
                    }
                    report.coAuthors = [];
                    report.users
                        .filter(ru => ru.role === Role.CoAuthor)
                        .forEach(ru => {
                            userService.getById(ru.userId)
                                .then(u => userService.getByKey(u.key))
                                .then(u => {
                                    report.coAuthors.push(u);
                                });
                            });
                }
                if (report.templateId) {
                    templateService.getById(report.templateId)
                        .then(t => {
                            report.template = t;
                        });
                }
                if (report.ingredients) {
                    report.ingredients = report.ingredients.map(ri => {
                        const ingred = new ReportIngredient(ri);
                        ingredientService.getById(ingred.ingredientId).then(i => {
                            ingred.ref = i;
                        });
                        return ingred;
                    });
                    report.ingredients.forEach(ri => {
                        ri.alternatives = ri.alternatives.map(ia => {
                            const alternative = new IngredientAlternative(ia);
                            ingredientService.getById(alternative.ingredientId).then(i => {
                                alternative.ref = i;
                            });
                            return alternative;
                        })
                    });
                // sort ingredients by amount, descending
                    report.ingredients.sort((a, b) => {
                        return b.percentMaxTrim() - a.percentMaxTrim();
                    });
                }
                if (report.textblocks) {
                    report.textblocks = report.textblocks.map(block => {
                        const tb = new Textblock(block);
                        templateService.getById(tb.templateId).then(t => {
                            tb.templateRef = t;
                        });
                        return tb;
                    });
                    report.contentTree = Textblock.buildTree(report.textblocks);
                    if (report.contentTree) {
                        report.contentTree.dataCtx = report;
                    }
                    report.textblocks = report.contentTree ? report.contentTree.asUiCondensedList() : null;
                }
                if (report.productTags && report.productTags.list) {
                    report.productTags.list = report.productTags.list.map(t => new ProductTag(t));
                }

                // Replace the light report stub, since we now have complete report object loaded
                dataService.reportsLight.replaceFirst(r => r.key, report);

                return report;
            };

            this.assertLoaded = (searchUri?: string) => {
                // do nothing
                this.data.list = [];
                return Promise.resolve();
            };

            this.create = (entity: Report) => {
                return super.create(entity)
                .then(respReport => {
                    this.reportLightService.reset();
                    return respReport;
                });
            };

            this.delete = (entity: Report) => {
                return super.delete(entity)
                .then(respReport => {
                    this.reportLightService.reset();
                    return respReport;
                });
            };
        }

    loadDetails(report: Report): Promise<Report> {
        const loadResult = () => {
            if (report.published && !report.result) {
                return this.http.get<Result>('/api/reports/key/' + report.key + '/result')
                    .toPromise()
                    .then(result => {
                        report.result = result;
                        return report;
                    });
            }
            return Promise.resolve(report);
        };

        if (report && report.id) {
            if (!report.ingredients || report.ingredients.length === 0) {
                return this.loadById(report.id)
                    .then(respReport => {
                        Object.assign(report, respReport);
                    })
                    .then(loadResult);
            } else {
                return loadResult();
            }
        } else {
            return Promise.reject();
        }
    }

    publish(report: Report): Promise<Result> {
        return this.http.post<Result>('/api/reports/key/' + report.key + '/publish', null)
            .toPromise()
            .then(respResult => {
                report.published = new Date();
                report.result = respResult;
                return respResult;
            });
    }

    reopen(report: Report): Promise<Report> {
        return this.http.post<Report>('/api/reports/key/' + report.key + '/reopen', null)
            .toPromise()
            .then(respReport => {
                this._postRead(respReport);
                Object.assign(report, respReport);
                return report;
            });
    }

    download(report: Report): Promise<Blob> {
        const config = {
            headers: {
                'Accept': 'application/octet-stream,*/*;q=0.9'
            },
            responseType: 'blob' as 'blob'
        };
        return this.http.get('/api/results/id/' + report.result.id + '/data', config)
            .toPromise()
            .then(data => {
                return new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            });
    }

}
