<div class="side-nav position-fixed">
  <ul class="nav flex-column">
    <li class="nav-item"><a class="nav-link" routerLink="/customers" routerLinkActive="active">Customers</a></li>
    <li class="nav-item"><a class="nav-link ps-2" routerLink="/customers/new" *ngIf="router.url.startsWith('/customers')"><small>New</small></a></li>

    <li class="nav-item"><a class="nav-link" routerLink="/ingredients" routerLinkActive="active">Raw Materials</a></li>
    <li class="nav-item"><a class="nav-link ps-2" routerLink="/ingredients/new" *ngIf="router.url.startsWith('/ingredients')"><small>New</small></a></li>

    <li class="nav-item"><a class="nav-link" routerLink="/incis" routerLinkActive="active">INCIs</a></li>
    <li class="nav-item"><a class="nav-link ps-2" routerLink="/incis/new" *ngIf="router.url.startsWith('/incis')"><small>New</small></a></li>

    <li class="nav-item" *ngIf="authService.currentUser().admin"><a class="nav-link" routerLink="/templates" routerLinkActive="active">Templates</a></li>
    <li class="nav-item" *ngIf="authService.currentUser().admin"><a class="nav-link ps-2" routerLink="/templates/new" *ngIf="router.url.startsWith('/templates')"><small>New</small></a></li>

    <!--    <li class="nav-item"><a class="nav-link" routerLink="/textblocks" routerLinkActive="active">Textblocks</a></li> -->
    <li class="nav-item"><a class="nav-link" routerLink="/docxs" routerLinkActive="active">Docx Files</a></li>

    <li><br/></li>

    <li class="nav-item"><a class="nav-link" routerLink="/specifications" routerLinkActive="active">Phys/Chem Specs</a></li>
    <li class="nav-item"><a class="nav-link ps-2" routerLink="/specifications/new" *ngIf="router.url.startsWith('/specifications')"><small>New</small></a></li>

    <li class="nav-item"><a class="nav-link" routerLink="/functions" routerLinkActive="active">Functions</a></li>
    <li class="nav-item"><a class="nav-link ps-2" routerLink="/functions/new" *ngIf="router.url.startsWith('/functions')"><small>New</small></a></li>

    <li class="nav-item"><a class="nav-link" routerLink="/values/correction" routerLinkActive="active">Corrections</a></li>
    <li class="nav-item"><a class="nav-link ps-2" routerLink="/values/correction/new" *ngIf="router.url.startsWith('/values/correction')"><small>New</small></a></li>

    <li class="nav-item"><a class="nav-link" routerLink="/values/product-tag" routerLinkActive="active">Product Tags</a></li>
    <li class="nav-item"><a class="nav-link ps-2" routerLink="/values/product-tag/new" *ngIf="router.url.startsWith('/values/product-tag')"><small>New</small></a></li>

    <li><br/></li>
    <li class="nav-item"><button (click)="refreshData()" class="btn btn-outline-secondary">Refresh Data</button></li>

    <li><br/><br/></li>
    <li class="nav-item">
      <a *ngIf="router.url.startsWith('/functions')" 
          class="nav-link dt-external-link" target="_refs"
          href="https://ec.europa.eu/growth/tools-databases/cosing/index.cfm?fuseaction=ref_data.functions">
        <span class="oi oi-external-link"></span> CosIng Functions</a>
    </li>
    <li class="nav-item">
      <a *ngIf="router.url.startsWith('/incis')"
          class="nav-link dt-external-link" target="_refs"
          href="https://ec.europa.eu/growth/tools-databases/cosing/index.cfm?fuseaction=search.simple">
        <span class="oi oi-external-link"></span> CosIng Search</a>
    </li>
    <li class="nav-item">
      <a *ngIf="router.url.startsWith('/incis')"
          class="nav-link dt-external-link" target="_refs"
          href="https://ec.europa.eu/growth/tools-databases/cosing/index.cfm?fuseaction=ref_data.annexes_v2">
        <span class="oi oi-external-link"></span> CosIng Annexes</a>
    </li>
  </ul>
</div>
