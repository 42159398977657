<div class="container-fluid">
  <div class="row">
    <app-sidenav class="col-lg-2 col-md-3 col-sm-4 col-6"></app-sidenav>
    <main class="col-lg-9 col-md-8 col-sm-7 col-5" role="main">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-9">
          <h2>Product Tags</h2>
        </div>
      </div>
      <app-alert></app-alert>

      <div class="container-fluid"> 
        <table class="table table-striped"><tbody>
          <tr>
            <th>Label</th>
            <th>Default</th>
          </tr>
          <tr *ngFor="let val of filteredValues()" class="entity" [ngClass]="{'table-active': data.values.selected && val.key==data.values.selected.key}">
            <td [ngClass]="{'dirty': val.dirty}"><span [ngbTooltip]="val.dirty ? 'Product tag has unsaved changes' : ''" tooltipClass="dt-tooltip" container="body">
              <a class="btn btn-link text-start" [routerLink]="['/values/product-tag', val.key, val.dirty ? 'edit' : '' ]">{{val.label}}</a>
            </span></td>
            <td><input class="form-check-input" disabled name="active" type="checkbox" [(ngModel)]="val.default"></td>
          </tr>
        </tbody></table>
      </div>

      <div  *ngIf="data.values.selected && !edit">
        <app-product-tag-show [val]="data.values.selected"></app-product-tag-show>
      </div>
      <div  *ngIf="data.values.selected && edit">
        <app-product-tag-edit [val]="data.values.selected"></app-product-tag-edit>
      </div>
  
    </main>
  </div>
</div>
