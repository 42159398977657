export const Constants = {

  LANG: [
    { key: 'de', label: 'German', decimal_separator: ',' },
    { key: 'en', label: 'English', decimal_separator: '.' }
  ],
  language(key: string) {
    return Constants.LANG.find(l => l.key === key) || Constants.LANG[0];
  },

  ADRESS_ROLE: {
    DEFAULT: 'default'
  },

  EUKV_APPENDIX: {
    values: [
      { value: 2, label: "Verbotene Stoffe"},
      { value: 3, label: "Stoffe mit Einschränkungen"},
      { value: 4, label: "Farbstoffe"},
      { value: 5, label: "Konservierungsstoffe"},
      { value: 6, label: "UV-Filter"},
    ],
    label(appendix: number) {
      return (this.values.find(a => a.value == appendix) || {label: '--'}).label;
    }
  },

  POD: {
    /* previously named NOAEL, wich is now just a special case of the more general term POD */

    ROUTES: {
      dermal: { label: 'Dermal', value: 'dermal', short: 'd', sort: 1, defaultAbsorption: 50 },
      oral: { label: 'Oral', value: 'oral', short: 'o', sort: 2, defaultAbsorption: 100 },
      inhalation: { label: 'Inhalation', value: 'inhalation', short: 'i', sort: 3, defaultAbsorption: 100 },
  
      all(): {label: string, value: string, short: string }[] { return [this.dermal, this.oral, this.inhalation]; },
      resolve(route: string): {label: string, value: string, short: string, sort: number, defaultAbsorption: number } {
        return this.all().find((r: {value: string}) => r.value == route);
      }
    },
  
    TYPES: [
      "NOAEL", "LOAEL", "BMDL"
    ],
  
    ABSORPTION_UNITS: {
      values: [
        { name: '%', label: '%'},
        { name: 'µg/cm²', label: 'µg/cm²'}
      ],
      resolve(name: string): { name: string, label: string } {
        return this.values.find(pu => pu.name == name);
      }
    }

  }

};
