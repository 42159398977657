import { Injectable, Predicate } from '@angular/core';
import { User, Customer, Report, Docx, Template, Textblock, Inci, Function, Ingredient, Specification, Value, InciRouteCorrection } from './model';
import { BaseEntity } from './model/base_entity';
import { Search } from './search';

export class DataList<T extends BaseEntity> {
    list: T[];
    selected: T;
    names?: {
        all: string[];
    } = {
        all: []
    };
    searchTerm?: string;
    prevSearchTerm?: string;
    searchResult?: T[];
    loading: {
        [uri: string]: Promise<T|Array<T>|void>;
    } = {};
    duplicatedEntity?: T;

    _preSearch?: (t: T) => T;

    search = (): T[] => {
        if (!this.list) {
            return [];
        }
        if (!this.searchTerm || this.searchTerm === '*' || this.searchTerm === '%') {
            this.prevSearchTerm = this.searchTerm;
            return this.searchResult = this.list;
        }
        if (this.searchTerm == this.prevSearchTerm) {
            return this.searchResult;
        }
        const search = new Search<T>(this.searchTerm);
        this.prevSearchTerm = this.searchTerm;
        return this.searchResult = search.run(this.list, this._preSearch);
    }

    filtered = (test: Predicate<T>): T[] => {
        if (this.list) {
            return this.list.filter(o => test(o));
        }
        return [];
    }

    findByKey = (key: number): T => {
        return this.list && key ? this.list.find(o => o.key === key) : null;
    }

    findById = (id: number): T => {
        return this.list && id ? this.list.find(o => o.id === id) : null;
    }

    isActive = (t: T): boolean => {
        return t && t.id && this.findById(t.id) != null;
    }

    sort = (sortFn: (a: T, b: T) => number ): void => {
        if (this.list && sortFn) {
            this.list.sort(sortFn);
        }
    }

    replaceFirst = (searchProp: (o: T) => any, t: T): void => {
        if (this.list) {
            const idx = this.list.findIndex(o => searchProp(o) == searchProp(t));
            if (idx > -1) {
                this.list[idx] = t;
            }
        }
    }

    related = (e: BaseEntity, role?: string): T[] => [];
}

class UserList extends DataList<User> {
    authors = () => this.filtered(u => u.author);
}
class TemplateList extends DataList<Template> {
    rootKeyFilter = '';
    roots = (language?: string) => this.filtered(t => t.root && (!language || t.language === language));

    search = (): Template[] => {
        if (!this.list) {
            return [];
        }
        const l = this.rootKeyFilter ? this.list.filter(t => t.templateKey === Number(this.rootKeyFilter)) : this.list;
        if (!this.searchTerm || this.searchTerm === '*' || this.searchTerm === '%') {
            this.prevSearchTerm = this.searchTerm;
            return this.searchResult = l;
        }
        if (this.searchTerm == this.prevSearchTerm) {
            return this.searchResult;
        }
        const search = new Search<Template>(this.searchTerm);
        this.prevSearchTerm = this.searchTerm;
        return this.searchResult = search.run(l, this._preSearch);
    }
}

class ValuesList extends DataList<Value> {
    inciRouteCorrections = () => this.filtered(v => v.type == 'route-correction');
    productTags = () => this.filtered(v => v.type == 'product-tag')
}

@Injectable({
    providedIn: 'root'
})
export class DataService {

    public users = new UserList();
    public customers = new DataList<Customer>();
    public ingredients = new DataList<Ingredient>();
    public reports = new DataList<Report>(); // always empty
    public reportsLight = new DataList<Report>();
    public docxs = new DataList<Docx>();
    public templates = new TemplateList();
    public textblocks = new DataList<Textblock>();
    public functions = new DataList<Function>();
    public specifications = new DataList<Specification>();
    public incis = new DataList<Inci>();
    public values = new ValuesList();

    public activeReport: Report;

    constructor() {
        this.ingredients._preSearch = (input: Ingredient) => {
            const i = JSON.parse(JSON.stringify(input));
            i.incis = [];
            return i;
        };
        this.templates._preSearch = (input: Template) => {
            const t = JSON.parse(JSON.stringify(input));
            t.rootTemplate = null;
            return t;
        };

        this.templates.related = (item, role?: string) => {
            if (item instanceof Template) {
                if (!role) {
                    return this.templates.filtered(t => {
                        return t.name === item.name
                                && t.inciName === item.inciName
                                && t.customerKey === item.customerKey
                                && t.key !== item.key;
                    });
                } else if (role === 'includedBy') {
                    return this.templates
                        .filtered(t => t.language === item.language)
                        .filter(t => {
                            const reTag = RegExp('<dt-include(-any)?[^>]*(' + item.name + ')("|,)[^>]*?>');
                            return t.dataText && reTag.test(t.dataText);
                        });
                } else if (role === 'includes') {
                    if (item.dataText) {
                        const regexs = [
                            RegExp('<dt-include[^>]*name="([\\w,_\-]+)"[^>]*>', 'g'),
                            RegExp('<dt-include[^>]*fallback="([\\w,_\-]+)"[^>]*>', 'g'),
                            RegExp('<dt-include-any[^>]*names="([\\w,_\-]+)"[^>]*>', 'g'),
                        ];
                        // const matches: Array<Array<string>> = item.dataText.matchAll(regexs);
                        // Hm, matchAll is only available in ES2020. So let's do it old-style:
                        // TODO: replace below lines:
                        const matches = [];
                        let m;
                        regexs.forEach(rx => {
                            while ((m = rx.exec(item.dataText)) !== null) {
                                matches.push(m[1])
                            }
                        });
                        const templates = this.templates.filtered(t => t.language === item.language)
                        return matches.map(names => names.split(','))
                            .reduce((flat, names) => flat.concat(names), [])
                            .map(name => templates.filter(t => t.name == name && t.customerKey == null && t.ingredientKey == null && t.inciName == null))
                            .reduce((flat, templates) => flat.concat(templates), []);
                    }
                    return [];
                }
            }
            if (item instanceof Inci) {
                return this.templates.filtered(t => {
                    return t.inciName === item.name;
                });
            }
            if (item instanceof Ingredient) {
                return this.templates.filtered(t => {
                    return t.ingredientKey === item.key;
                });
            }
            if (item instanceof Customer) {
                return this.templates.filtered(t => {
                    return t.customerKey === item.key;
                });
            }
            return [];
        };

        this.incis.related = (item) => {
            if (item instanceof Template) {
                return this.incis.filtered(i => {
                    return i.name === item.inciName;
                });
            }
            return [];
        };

        this.customers.related = (item) => {
            if (item instanceof Template) {
                const c = this.customers.findByKey(item.customerKey);
                return c ? [c] : [];
            }
            return [];
        };
    }
}
