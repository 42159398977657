import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, ComponentDeactivate } from '../../../common';
import { DataService, SpinnerService, NotifyService } from '../../../common';
import { ProductTag } from '../../../common/model';
import { ProductTagEditComponent } from './product-tag-edit.component';

@Component({
  selector: 'app-product-tag-new',
  templateUrl: './product-tag-new.component.html'
})
export class ProductTagNewComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild(ProductTagEditComponent, { static: true }) valueEdit: ProductTagEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService) {
    super(spinner, notify, router, data);
    console.log('ProductTagNewComponent.create()');
  }

  ngOnInit() {
    console.log('ProductTagNewComponent.init()');
    this.data.values.selected = new ProductTag();
  }

  canDeactivate() {
    return this.valueEdit.canDeactivate();
  }
}
