import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ConfirmComponent, HtmlUtil } from '../../common';
import { Ingredient } from '../../common/model';
import { IngredientService } from '../../common/services';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ingredient-show',
  templateUrl: './ingredient-show.component.html'
})
export class IngredientShowComponent extends BaseMainpage implements OnInit {

  @Input() ingredient: Ingredient;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private sanitizer: DomSanitizer,
      private ingredientService: IngredientService) {
    super(spinner, notify, router, data);
    console.log('IngredientShowComponent.create()');
  }

  ngOnInit() {
    console.log('IngredientShowComponent.init()');
  }

  duplicate() {
    // clone current template
    const i = this.ingredientService._preSave(this.ingredient);
    i.id = null;
    i.key = null;
    this.data.ingredients.duplicatedEntity = this.ingredientService._postRead(new Ingredient(i));
    this.router.navigate(['/ingredients/new']);
  }

  delete() {
    this.confirm.open('Raw Material', 'Delete Raw Material: ' + this.ingredient.name + ' ?', 'Delete')
      .then(() => {
        this.startSpinner();
        this.ingredientService.delete(this.ingredient)
          .then(() => {
            this.router.navigate(['/ingredients']);
          })
          .finally(() => {
            this.stopSpinner();
          });
      })
      .catch(() => { /* do nothing */ });
  }

  text2html(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(HtmlUtil.text2html(text));
  }

  specListFiltered() {
    return this.ingredient.specifications.list.filter(spec => spec.value != null);
  }
}
