<div class="container-fluid">
  <br>
  <div class="row">
    <div class="col-11">
      <h3>{{ingredient.tradename ? ingredient.tradename : ingredient.name}}</h3>

      <div class="row">
        <label class="col-12 col-md-3 col-lg-3 col-form-label">Trade Name:</label>
        <div class="col-12 col-md-9 col-lg-3"><span class="form-control-plaintext">{{ingredient.tradename}}</span></div>
        <label class="col-12 col-md-3 col-lg-3 col-form-label">Name / Customer's Internal Denomination:</label>
        <div class="col-12 col-md-9 col-lg-3"><span class="form-control-plaintext">{{ingredient.name}}</span></div>
      </div>
      <div class="row">
        <label class="col-12 col-md-3 col-form-label">Manufacturer:</label>
        <div class="col-12 col-md-9"><span class="form-control-plaintext">{{ingredient.manufacturer}}</span></div>
      </div>
      <div class="row" *ngIf="ingredient.literatureRef">
        <label class="col-12 col-md-3 col-form-label">Literature Reference:</label>
        <div class="col-12 col-md-9"><span class="form-control-plaintext">{{ingredient.literatureRef}}</span></div>
        <label class="col-12 col-md-3 col-form-label">Language:</label>
        <div class="col-12 col-md-9 col-lg-3"><span class="form-control-plaintext">{{ingredient.language}}</span></div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 form-goup form-check form-check-aligned">
          <input class="form-check-input" type="checkbox" readonly disabled [(ngModel)]="ingredient.perfume">
          <label class="form-check-label">Perfume</label>
        </div>
        <label class="col-12 col-md-3 col-form-label" *ngIf="ingredient.perfume">Code of composition:</label>
        <div class="col-12 col-md-9 col-lg-3" *ngIf="ingredient.perfume"><span class="form-control-plaintext">{{ingredient.codeNo}}</span></div>
      </div>
      <div class="row row-center" *ngIf="ingredient.perfume">
        <div class="col-6 col-lg-3 form-group form-check form-check-aligned">
          <input class="form-check-input" type="checkbox" readonly disabled [(ngModel)]="ingredient.allergenList">
          <label class="form-check-label">Allergene List</label>
        </div>
        <div class="col-6 col-lg-3 form-group form-check form-check-aligned">
          <input class="form-check-input" type="checkbox" readonly disabled [(ngModel)]="ingredient.perfumeSecurityReport">
          <label class="form-check-label">Perfume Security Report</label>
        </div>
        <div class="col-6 col-lg-3 form-group form-check form-check-aligned">
          <input class="form-check-input" type="checkbox" readonly disabled [(ngModel)]="ingredient.ifraCert">
          <label class="form-check-label">IFRA Certificate</label>
        </div>
        <div class="col-6 col-md-3">
          <label>IFRA-Test Revision:</label>
          <span class="form-control-plaintext">{{ingredient.ifraCertRevision}}</span>
        </div>
      </div>
  
      <div class="row">
        <div class="col-12 col-md-3 col-form-label">INCI components:</div>
        <div class="col-12 col-md-9"><div class="form-control-plaintext">
          <table class="table table-striped"><tbody>
            <tr>
              <th>Name</th>
              <th>Proportion (%)</th>
            </tr>
            <tr *ngFor="let inci of ingredient.incis">
              <td>
                <a *ngIf="!inci.ref.obsolete" [routerLink]="['/incis', inci.ref.key]" class="nav-link">{{inci.ref.name}}</a>
                <span *ngIf="inci.ref.obsolete" ngbTooltip="Key: {{inci.ref.key}}" tooltipClass="dt-tooltip" container="body">{{inci.ref.name}}</span>
              </td>
              <td>{{inci.formatPercent('en')}}</td>
            </tr>
          </tbody></table>
        </div></div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3 col-form-label">Phys./Chem. Specifications:</div>
        <div class="col-12 col-md-9"><div class="form-control-plaintext">
          <table class="table table-striped"><tbody>
            <tr>
              <th>Name</th>
              <th>Unit</th>
              <th class="text-center">Value</th>
            </tr>
            <tr *ngFor="let spec of specListFiltered()">
              <td>{{spec.ref.label}} <span *ngIf="spec.ref.supplement">(<span [innerHTML]="text2html(spec.ref.supplement)"></span>)</span></td>
              <td [innerHTML]="text2html(spec.ref.unit)"></td>
              <td><span [innerHTML]="text2html(spec.value)"></span></td>
            </tr>
            <tr>
              <td *ngIf="!specListFiltered().length" colspan="3" class="text-center">-- Empty --</td>
            </tr>
          </tbody></table>
        </div></div>
      </div>

      <!-- Related Items: -->
      <div class="row">
        <div class="col-12 col-md-6 form-group" *ngIf="data.templates.related(ingredient).length">
          <label>Related Templates:</label>
          <div>
            <a *ngFor="let t of data.templates.related(ingredient)"
                class="me-2 nav-link" [routerLink]="['/templates', t.key]">{{t.name}}<sub>({{t.language}})</sub></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-1">
      <div class="form-group" ngbTooltip="Edit" tooltipClass="dt-tooltip">
        <a [routerLink]="['/ingredients', ingredient.key, 'edit']" class="form-control btn"><span class="float-end oi oi-pencil"></span></a>
      </div>
      <div class="form-group" ngbTooltip="Duplicate" tooltipClass="dt-tooltip">
        <button (click)="duplicate()" class="form-control btn"><span class="float-end oi oi-layers"></span></button>
      </div>
      <div class="form-group" ngbTooltip="Delete" tooltipClass="dt-tooltip">
        <button (click)="delete()" class="form-control btn"><span class="float-end oi oi-x"></span></button>
      </div>
    </div>
  </div>
  <app-confirm #confirm></app-confirm>
</div>
